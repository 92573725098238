import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui';

import Login from "@/views/Login.vue"
import Home from "@/views/Home.vue"
import Page404 from "@/views/Page404.vue"
import Callback from "@/views/Callback.vue"
// 
import DashBoard from "@/views/DashBoard.vue"
// 
import Account from "@/views/Auth/Account.vue"
import Roles from "@/views/Auth/Roles.vue"
// User

// Channel
import ChannelData from "@/views/Channel/ChannelData.vue"//渠道数据
import ChannelManage from "@/views/Channel/ChannelManage.vue"
// Order
import Deduction from "@/views/Order/Deduction.vue" //扣量管理
import Commission from "@/views/Order/Commission.vue" //佣金明细
import Commission1 from "@/views/Order/Commission-1.vue" //佣金明细下级代理
import Billing from "@/views/Order/Billing.vue"//结算管理

// Product
import ProductManage from "@/views/Product/ProductManage.vue"  //产品管理 

// Announcement
import Announcement from "@/views/Announcement/Announcement.vue"//公告管理

// sharePage
import SharePage from "@/views/SharePage/SharePage.vue"//分享管理

import NProgress from 'nprogress/nprogress'
Vue.use(VueRouter)

// 公司
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/callback/:callback',
    name: 'Callback',
    meta: { title: "登录页验证" },
    component: Callback
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: "登录页" },
    component: Login
  },
  {
    path: '/404',
    name: 'Page404',
    meta: { title: "Not Found" },
    component: Page404
  },
  {
    path: '/home',
    name: 'Home',
    redirect: '/dashboard',
    meta: { title: "首页" },
    component: Home,
    children: [
      {
        path: '/dashboard',
        name: 'DashBoard',
        meta: { title: "首页概况" },
        component: DashBoard
      },
      {
        path: '/account',
        name: 'Account',
        component: Account
      },
      {
        path: "/roles",
        name: "Roles",
        component: Roles,
      },
      // 
      {
        path: "/channeldata",
        name: "ChannelData",
        meta: { title: "渠道数据" },
        component: ChannelData,
      },
      {
        path: "/channelmanage",
        name: "ChannelManage",
        meta: { title: "渠道管理" },
        component: ChannelManage,
      },
      // Order
      {
        path: "/deduction",
        name: "Deduction",
        meta: { title: "扣量管理" },
        component: Deduction,
      },
      {
        path: "/commission",
        name: "Commission",
        meta: { title: "佣金明细" },
        component: Commission,
      },
      {
        path: "/commission/:id",
        name: "Commission-1",
        meta: { title: "佣金明细下级代理" },
        component: Commission1,
      },
      {
        path: "/billing",
        name: "Billing",
        meta: { title: "结算管理" },
        component: Billing,
      },
      // User

      // Product
      {
        path: '/productmanage',
        name: 'ProductManage',
        meta: { title: '产品管理' },
        component: ProductManage,
      },

      // announcement
      {
        path: '/announcement',
        name: 'Announcement',
        meta: { title: '公告管理' },
        component: Announcement,
      },
      // sharepage
      {
        path: '/sharepage',
        name: 'SharePage',
        meta: { title: '分享页' },
        component: SharePage,
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  },


]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  NProgress.start();

  let isAuthenticated = sessionStorage.getItem('token') ? true : false
  // if (to.fullPath !== '/login') {
  //   let a = sessionStorage.getItem('token')
  //   console.log(a);
  // }

  // if (to.name !== 'Login' && !isAuthenticated) {
  //   Message({
  //     showClose: true,
  //     message: '请重新登录！',
  //     type: 'error'
  //   })
  //   next({ path: '/login' })
  // }
  // else next()
  next()
})
router.afterEach((to, from) => {
  NProgress.done();
})
