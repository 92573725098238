<template>
  <div>
    <!-- <img :src="tempImg" alt=""> -->
    <el-upload
      action="aliyun"
      ref="elupload"
      list-type="picture-card"
      :file-list="imgList"
      :limit="limit"
      :auto-upload="false"
      :on-change="handleChange"
      :on-exceed="handleExceed"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
import { getSTS, getAliSTSUrl } from "../../utils/common.js";
let OSS = require("ali-oss");

export default {
  name: "UploadImg",
  props: ["img", "count"], //  img 是外部传入的参数
  data() {
    return {
      limit: 9,
      aliSTS: [], //访问阿里云图片的登录信息
      imgList: [], //el-upload 图片存储数组
      imgUrl: [], //存储阿里云上传图片返回的图片链接
      tempImg: "", //传入组件的图片地址
    };
  },
  created() {
    this.preHandle();
    console.log(this.img);
    this.limit = parseInt(this.count);
    console.log(this.limit, "查看限制数");
  },
  mounted() {
    console.log(this.img);
    getSTS();
    // 如果打开组件 图片url为空 就不管 如果有图就先隐藏添加框
    if (this.imgList.length >= this.limit) {
      let addBox = this.$refs.elupload.$children[1].$el;
      addBox.style.cssText = "display: none;";
    }
  },
  methods: {
    // --------------------请求--------------------
    // 向阿里云发送图片请求返回图片地址链接
    async putObject(name, buffer) {
      //
      let _this = this;
      let id = sessionStorage.getItem("AccessKeyId");
      let secret = sessionStorage.getItem("AccessKeySecret");
      let token = sessionStorage.getItem("SecurityToken");
      //
      let client = new OSS({
        region: "oss-ap-southeast-1",
        accessKeyId: id,
        accessKeySecret: secret,
        stsToken: token,
        bucket: "batfeeds", //这个参数要问运维
        secure: true,
      });
      this.$message.success('图片成功上传阿里云')
      let result = await client.put(`game/${name}`, buffer);
      console.log(result.url, "查看上传阿里云的文件");
      // Key
      // let key = `picture/` + name;
      // console.log(key);

      // this.$emit("imgKey", key);
      // console.log(this.imgUrl);
      this.imgUrl.push(result.url);
      this.nextHandle();
    },
    // --------------------处理函数--------------------
    /**
     * 组件外的图片数组是['key']
     * 组件内的图片数组是[{url:'https://1.jpg'}]
     * 对传入组件的数组进行处理
     * */
    preHandle() {
      
      let tempArr = [];
      let t = this.img
      if(typeof(t) === 'string'){
        t = [t]
      }
      for (let item of t) {
        // console.log(item);
        // let tempObj = {url:getAliSTSUrl(item)};//如果是key就要转一下
        let tempObj = {url:item};
        tempArr.push(tempObj);
      }
      this.imgList = tempArr;
      this.imgUrl = JSON.parse(JSON.stringify(t));

      // console.log(this.imgUrl, "传入的key数组");
    },
    nextHandle() {
      // console.log(this.imgUrl);
      this.$emit("imgKey", this.imgUrl);
    },
    // 处理upload拿到的图片文件 调用putObject
    handleImg(e) {
      let _this = this;
      let file = e;
      // 文件名中文 有问题
      file.name = file.uid + file.name; //文件名唯一
      // file.name = 'picture'+file.uid ; //文件名唯一
      console.log(file);
      let data = new Blob([file.raw], { type: "image/jpeg" });
      file.blob = data;
      file.fileByte = new FileReader();
      file.fileByte.readAsArrayBuffer(data);
      file.fileByte.onload = function (event) {
        let buffer = new OSS.Buffer(event.target.result);
        _this.putObject(file.name, buffer);
      };
      // console.log(file, "文件准备");
    },
    /**
     * 判断当前图片数组长度和限制，隐藏或显示添加按钮
     */
    turnAddBtn() {
      if (this.imgList.length >= this.limit) {
        // 隐藏
        let addBox = this.$refs.elupload.$children[1].$el;
        addBox.style.cssText = "display: none;";
      } else {
        // 显示
        let addBox = this.$refs.elupload.$children[1].$el;
        addBox.style.cssText = "display: inline-block;";
      }
    },
    // --------------------upload处理函数--------------------
    // 修改上传图片
    handleChange(file, fileList) {
      // 确保图片数量没有超出上限
      if (fileList.length <= this.limit) {
        this.imgList = fileList;
        this.handleImg(file);
      }
      this.turnAddBtn();
    },
    // 上传文件超出个数限制(limit控制,当前写死为1)
    handleExceed(files, fileList) {
      this.$message.error(`最多上传${this.limit}张图片哦！`);
    },
    // 删除上传图片
    handleRemove(file, fileList) {
      // console.log(file.uid);
      // console.log(fileList);
      for (let item in this.imgList) {
        if (this.imgList[item].uid === file.uid) {
          // console.log(item);
          this.imgUrl.splice(item, 1);
        }
      }
      this.imgList = fileList;
      this.turnAddBtn();
      this.nextHandle();
    },
    // 点击放大上传图片
    handlePictureCardPreview(file) {
      this.$emit("dialog", file.url); // 传图片的链接
    },
  },
};
</script>