<template>
  <article>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>结算管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询参数 -->
    <el-form :inline="true" :model="queryInfo" size="small">
      <el-form-item label="支付状态：">
        <el-select v-model="queryInfo.settleState" style="width: 100px">
          <el-option label="全部" value="0"></el-option>
          <el-option label="未结算" value="1"></el-option>
          <el-option label="已结算" value="2"></el-option>
          <el-option label="已拒绝" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="渠道ID：">
        <el-input v-model="queryInfo.agentID"></el-input>
      </el-form-item>

      <el-form-item label="时间选择：">
        <el-date-picker
          v-model="queryInfo.timeRange"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleClick('select')"
          >查 询</el-button
        ><el-button type="primary" @click="handleClick('resetSelect')"
          >重 置</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="billingList" style="width: 100%" border>
      <el-table-column
        prop="billing.date"
        label="时间"
        width="105"
        v-if="queryInfo.timeRange === null"
      ></el-table-column>

      <el-table-column
        label="查询截止时间"
        v-if="queryInfo.timeRange !== null"
        width="185"
      >
        {{ this.queryInfo.timeRange[0] | dateFormat("YYYY-mm-dd") }} ~
        {{ this.queryInfo.timeRange[1] | dateFormat("YYYY-mm-dd") }}
      </el-table-column>
      <el-table-column prop="billing.ID" label="ID"></el-table-column>
      <el-table-column prop="billing.agentID" label="渠道ID"></el-table-column>
      <el-table-column prop="appName" label="所属产品"></el-table-column>
      <el-table-column
        prop="billing.parentAgentID"
        label="父亲渠道ID"
      ></el-table-column>
      <el-table-column prop="billing.goldCommission" label="金币佣金">
        <template slot-scope="scope">
          <span v-if="scope.row.billing.goldCommission"
            >{{ scope.row.billing.goldCommission.toFixed(2) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="billing.vipCommission" label="VIP佣金">
        <template slot-scope="scope">
          <span v-if="scope.row.billing.vipCommission">{{
            scope.row.billing.vipCommission.toFixed(2)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="billing.inviteAgentRewards"
        label="邀请下级代理奖励"
      ></el-table-column>
      <el-table-column
        prop="billing.inviteUserSubsidy"
        label="邀请用户奖励"
      ></el-table-column>
      <el-table-column
        prop="billing.childGoldCommission"
        label="下级金币佣金"
        width="105"
      ></el-table-column>
      <el-table-column
        prop="billing.childVipCommission"
        label="下级VIP佣金"
        width="105"
      ></el-table-column>
      <el-table-column label="结算状态">
        <template slot-scope="scope">
          <span v-if="scope.row.billing.settleState === 1">未结算</span>
          <span v-if="scope.row.billing.settleState === 2">已结算</span>
          <span v-if="scope.row.billing.settleState === 3">已拒绝</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="financialAccount.account"
        label="提现账户"
      ></el-table-column>
      <el-table-column
        prop="financialAccount.info"
        label="账户信息"
      ></el-table-column>
      <el-table-column
        prop="financialAccount.cate"
        label="提现账号类别"
      ></el-table-column>

      <el-table-column label="操作" width="125">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.billing.settleState === 1"
            type="primary"
            @click="handleClick('update', scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 修改dialog -->
    <el-dialog title="修改" :visible.sync="updateDialogVisible" width="30%">
      <span>
        <el-form
          :model="updateForm"
          :rules="updateFormRules"
          ref="updateForm"
          label-width="140px"
          class="demo-ruleForm"
        >
          <el-form-item label="agentID" prop="agentID">
            <el-input v-model="updateForm.agentID" disabled></el-input>
          </el-form-item>
          <el-form-item label="修改佣金结算状态" prop="pass">
            <el-select v-model="updateForm.pass" placeholder="请选择">
              <el-option label="通过" :value="true"></el-option>
              <el-option label="拒绝" :value="false"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('updateForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </article>
</template>

<script>
import { _getBilling, _putBilling, _getAgentByID } from "../../api/index.js";
export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      queryInfo: {
        settleState: "0", //结算状态 1: 未结算 2：已结算 3：已拒绝
        agentID: "", //渠道ID
        timeRange: null,
      },
      billingList: [],
      //
      updateDialogVisible: false,
      //
      updateForm: {
        ID: 0,
        pass: false,
      },
      updateFormRules: {},
    };
  },
  mounted() {
    this.getBilling();
  },
  methods: {
    // --------------------请求函数--------------------
    async getBilling() {
      let res = await _getBilling({
        page: this.page,
        pageSize: this.pageSize,
        settleState: parseInt(this.queryInfo.settleState),
        agentID:
          this.queryInfo.agentID === "" ? 0 : parseInt(this.queryInfo.agentID),
        startDate:
          this.queryInfo.timeRange === null
            ? ""
            : this.dateFormat(this.queryInfo.timeRange[0], "YYYY-mm-dd"),
        endDate:
          this.queryInfo.timeRange === null
            ? ""
            : this.dateFormat(this.queryInfo.timeRange[1], "YYYY-mm-dd"),
      });
      if (res && (res.status === 200 || res.status === 204)) {
        this.billingList = res.data.list;
        this.total = res.data.total;
        console.log(this.billingList, "billing列表");
      }
    },
    //
    async putBilling() {
      if (this.queryInfo.timeRange === null) {
        // console.log(1);
        let res = await _putBilling({
          agentID: this.updateForm.agentID,
          ID: this.updateForm.ID,
          pass: this.updateForm.pass,
        });
        if (res && (res.status === 200 || res.status === 204)) {
          console.log(res);
          this.getBilling();
          this.updateDialogVisible = false;
        }
      } else {
        // console.log(2);
        // console.log(this.dateFormat(this.queryInfo.timeRange[0], "YYYY-mm-dd"));
        // console.log(this.dateFormat(this.queryInfo.timeRange[1], "YYYY-mm-dd"));
        let res = await _putBilling({
          agentID: this.updateForm.agentID,
          // ID: this.updateForm.ID,
          pass: this.updateForm.pass,
          startDate: this.dateFormat(this.queryInfo.timeRange[0], "YYYY-mm-dd"),
          endDate: this.dateFormat(this.queryInfo.timeRange[1], "YYYY-mm-dd"),
        });
        if (res && (res.status === 200 || res.status === 204)) {
          console.log(res);
          this.getBilling();
          this.updateDialogVisible = false;
        }
      }
    },
    async getAgentByID(id) {
      let res = await _getAgentByID({
        ID: id,
      });
      if (res && res.status === 200) {
        if (
          res.data.agent.financialAccount &&
          res.data.agent.financialAccount.account &&
          res.data.agent.financialAccount.info
        ) {
          this.updateDialogVisible = true;
        } else {
          this.$message.error("此用户没有提现账户信息!");
        }
      }
    },
    handleClick(type, e) {
      // console.log(e,"打印参数");
      switch (type) {
        case "select":
          console.log(this.queryInfo, "打印查询参数");
          this.getBilling();
          break;
        case "resetSelect":
          console.log("重置查询参数并请求");
          this.queryInfo = {
            settleState: "0", //结算状态 1: 未结算 2：已结算 3：已拒绝
            agentID: "", //渠道ID
            timeRange: null,
          };
          this.getBilling();
          break;

        case "update":
          console.log(e);
          this.updateForm.agentID = e.billing.agentID;
          this.updateForm.ID = e.billing.ID;
          console.log(this.updateForm, "22");
          this.getAgentByID(this.updateForm.agentID);
        // this.updateDialogVisible = true;
        default:
          break;
      }
    },
    //
    handleSizeChange(val) {
      this.page = 1;
      this.pageSize = val;
      console.log(`每页 ${val} 条`);
      this.getBilling();
    },
    handleCurrentChange(val) {
      this.page = val;
      console.log(`当前页: ${val}`);
      this.getBilling();
    },
    dateFormat(date, fmt) {
      let ret = "";
      date = new Date(date);

      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "updateForm":
              console.log(this.updateForm, "更新表单");
              this.putBilling();
              break;

            default:
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>