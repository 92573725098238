import { http } from "@/utils/http.js"
import { _getSTS } from '../api/index.js'

let OSS = require("ali-oss");
export function getAliSTSUrl(e, option) {
    getSTS()
    let id = sessionStorage.getItem("AccessKeyId");
    let secret = sessionStorage.getItem("AccessKeySecret");
    let token = sessionStorage.getItem("SecurityToken");
    let store = new OSS({
        accessKeyId: id,
        accessKeySecret: secret,
        stsToken: token,
        region: "oss-ap-southeast-1",
        bucket: "batfeeds",
    });
    let myurl = store.signatureUrl(e, option);
    return myurl;
}
// 请求阿里云STS
export function getSTS() {
    let id = sessionStorage.getItem("AccessKeyId");
    let secret = sessionStorage.getItem("AccessKeySecret");
    let time = sessionStorage.getItem("Expiration");
    let token = sessionStorage.getItem("SecurityToken");
    // 判断session中是否有，没有就请求，有就判断有没有过期是不是要重新请求
    if (id == null && secret == null && time == null && token == null) {
        console.log("没有STStoken第一次请求");
        STS();
    } else {
        time = new Date(time).getTime();
        let nowTime = Date.now();
        if (time <= nowTime) {
            console.log("STStoken过期重新请求");
            STS();
        } else {
            // console.log("现有STStoken没过期");
        }
    }
}
export async function STS() {
    let res = await _getSTS({});
    if (res && res.status === 200) {
        sessionStorage.setItem("AccessKeyId", res.data.data.AccessKeyId);
        sessionStorage.setItem("AccessKeySecret", res.data.data.AccessKeySecret);
        sessionStorage.setItem("Expiration", res.data.data.Expiration);
        sessionStorage.setItem("SecurityToken", res.data.data.SecurityToken);
    }
}

export const appOptions = [
    { value: '0', label: '全部' },
    // { value: '1', label: 'Bat' },
    // { value: '2', label: 'JoyChat' },
    // { value: '3', label: 'Halo' },
    // { value: '4', label: 'Tume' },
    // { value: '5', label: 'Fungo' },
    // { value: '6', label: 'Helly' },
    // { value: '7', label: 'Kizz' },
    // { value: '8', label: 'Tof' },
    // { value: '9', label: 'ChatU' },
    // { value: '10', label: 'PopChat' },
    // { value: '11', label: 'PureLive' },
    // { value: '12', label: 'Helly2' },
    // { value: '13', label: 'MeetU' },
]
export const appOptions1 = [
    // { value: '1', label: 'Bat' },
    // { value: '2', label: 'JoyChat' },
    // { value: '3', label: 'Halo' },
    // { value: '4', label: 'Tume' },
    // { value: '5', label: 'Fungo' },
    // { value: '6', label: 'Helly' },
    // { value: '7', label: 'Kizz' },
    // { value: '8', label: 'Tof' },
    // { value: '9', label: 'ChatU' },
    // { value: '10', label: 'PopChat' },
    // { value: '11', label: 'PureLive' },
    // { value: '12', label: 'Helly2' },
    // { value: '13', label: 'MeetU' },
]

// 将值放入对象中遍历 将匹配的key转成value或将value转成key
// 这里的key和value唯一
// val是要匹配转换的值，obj是key-value对应的数组
export function objectKey2Value(val, obj) {
    console.log(val, 'common.js-objectKey2Value-val');
    console.log(obj, 'common.js-objectKey2Value-obj');
    for (const item in obj) {
        if (val === item) {
            console.log('common.js-objectKey2Value-if1');
            return obj[item]
        }
        if (val === obj[item]) {
            console.log('common.js-objectKey2Value-if2');
            return item
        }
    }
}

export function debounce(fn, delay) {
    console.log("点击")
    var timer = null;
    var delay = delay || 200;
    return function (e) {
        console.log(e)
        var args = arguments;
        var that = this;
        clearTimeout(timer)
        timer = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}
