<template>
  <article>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        ><a href="/commission">佣金明细</a></el-breadcrumb-item
      >
      <el-breadcrumb-item>下级代理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询参数 -->
    <el-form :inline="true" :model="queryInfo" size="small">
      <el-form-item label="代理ID：">
        <el-input v-model="queryInfo.agentID" disabled></el-input>
      </el-form-item>
      <el-form-item label="产品：">
        <el-select v-model="queryInfo.appID" style="width: 100px">
          <el-option
            v-for="item in appOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="时间选择：">
        <el-date-picker
          v-model="queryInfo.timeRange"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleClick('select')"
          >查 询</el-button
        >
        <el-button type="primary" @click="handleClick('resetSelect')"
          >重 置</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="detailList" style="width: 100%" border>
      <el-table-column prop="billing.ID" label="ID"></el-table-column>
      <el-table-column prop="billing.agentID" label="代理ID"></el-table-column>
      <el-table-column
        prop="billing.parentAgentID"
        label="上级代理ID"
      ></el-table-column>
      <el-table-column
        prop="billing.date"
        label="时间"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="billing.goldCommission"
        label="金币佣金"
      ></el-table-column>
      <el-table-column
        prop="billing.vipCommission"
        label="VIP佣金"
      ></el-table-column>
      <el-table-column
        prop="billing.inviteAgentRewards"
        label="邀请下级代理奖励"
      ></el-table-column>
      <el-table-column
        prop="billing.inviteUserSubsidy"
        label="邀请用户奖励"
      ></el-table-column>
      <el-table-column
        prop="billing.childGoldCommission"
        label="下级金币抽成"
      ></el-table-column>
      <el-table-column
        prop="billing.childVipCommission"
        label="下级VIP抽成"
      ></el-table-column>
      <el-table-column prop="billing.settleState" label="结算状态">
        <template slot-scope="scope">
          <span v-if="scope.row.billing.settleState === 1">未结算</span>
          <span v-if="scope.row.billing.settleState === 2">已结算</span>
          <span v-if="scope.row.billing.settleState === 3">已拒绝</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" width="125">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="handleClick('agentDetail', scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryInfo.total"
    >
    </el-pagination>
  </article>
</template>

<script>
import { _getCommission,_getProduct } from "../../api/index.js";
export default {
  data() {
    return {
      queryInfo: {
        page: 1,
        pageSize: 10,
        total: 0,
        appID: "0",
        agentID: "",
        timeRange: [
          new Date(new Date().getTime() - 3600 * 24 * 30 * 1000),
          new Date(),
        ],
      },
      detailList: [],
      productList: [],
      appOptions: [{label:"全部",value:'0'}],
    };
  },

  mounted() {
    // console.log(this.$route.params.id)
    this.queryInfo.agentID = this.$route.params.id
    this.getProductList()
    this.getCommission();
  },
  methods: {
    // --------------------请求函数--------------------
    async getCommission() {
      let res = await _getCommission({
        appID: parseInt(this.queryInfo.appID),
        agentID:
          this.queryInfo.agentID === "" ? 0 : parseInt(this.queryInfo.agentID),
        startDate: this.dateFormat(this.queryInfo.timeRange[0], "YYYY-mm-dd"),
        endDate: this.dateFormat(this.queryInfo.timeRange[1], "YYYY-mm-dd"),
        page: this.queryInfo.page,
        pageSize: this.queryInfo.pageSize,
        searchChildren: true,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res);
        this.detailList = res.data.list;
        this.queryInfo.total = res.data.total;
      }
    },
     async getProductList() {
      let result = await _getProduct({});
      if (result && result.status === 200) {
        this.productList = result.data.list;
        console.log(this.productList);
        for (const key in this.productList) {
          let obj = {
            value: this.productList[key].ID,
            label: this.productList[key].name,
          };
          this.appOptions.push(obj);
        }
        console.log(this.appOptions, "options");
      }
    },
    // --------------------处理函数--------------------
    handleClick(type, e) {
      // console.log(e);
      switch (type) {
        case "select":
          this.getCommission();
          break;
        case "resetSelect":
          this.queryInfo = {
            page: 1,
            pageSize: 10,
            total: 0,
            appID: "0",
            agentID: "",
            timeRange: [
              new Date(new Date().getTime() - 3600 * 24 * 30 * 1000),
              new Date(),
            ],
          };
          this.getCommission();
          break;
       
        default:
          break;
      }
    },
    // --------------------公告函数--------------------
    handleSizeChange(val) {
      this.queryInfo.page = 1;
      this.queryInfo.pageSize = val;
      console.log(`每页 ${val} 条`);
      this.getCommission();
    },
    handleCurrentChange(val) {
      this.queryInfo.page = val;
      console.log(`当前页: ${val}`);
      this.getCommission();
    },
    dateFormat(date, fmt) {
      let ret = "";
      date = new Date(date);

      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
};
</script>