<template>
  <div>
    <quill-editor v-model="content" ref="myQuillEditor" :options="editorOption" @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)" @change="onEditorChange($event)">
    </quill-editor>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  props: ['text'],
  components: {
    quillEditor,
  },
  mounted() {
    // console.log(this.text);
    this.content = this.text
  },
  data() {
    return {
      content: ``, //双向数据绑定数据
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"], //加粗，斜体，下划线，删除线
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            ["clean"], //清除字体样式
          ],
        },
        placeholder: "输入内容...",
      },
      //   editorOption: {
      //     modules: {
      //       toolbar: [
      //         ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
      //         ["blockquote", "code-block"], //引用，代码块
      //         [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
      //         [{ list: "ordered" }, { list: "bullet" }], //列表
      //         [{ script: "sub" }, { script: "super" }], // 上下标
      //         [{ indent: "-1" }, { indent: "+1" }], // 缩进
      //         [{ direction: "rtl" }], // 文本方向
      //         [{ size: ["small", false, "large", "huge"] }], // 字体大小
      //         [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
      //         [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
      //         [{ font: [] }], //字体
      //         [{ align: [] }], //对齐方式
      //         ["clean"], //清除字体样式
      //         ["image", "video"], //上传图片、上传视频
      //       ],
      //     },
      //     placeholder: "输入内容...",
      //   },
    };
  },
  methods: {
    onEditorBlur() { }, // 失去焦点触发事件
    onEditorFocus() { }, // 获得焦点触发事件
    onEditorChange() { }, // 内容改变触发事件
  },
  watch: {
    content(newText, oleText) {
      console.log(oleText, 'old');
      console.log(newText, 'new');
      this.$emit('updateText', newText)
    }
  }
};
</script>





