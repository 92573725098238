export const sidebar = [
    {
        icon: "el-icon-odometer",
        index: "/dashboard",
        title: "首页",
    },
    {
        icon: "el-icon-s-custom",
        index: "/account",
        title: "权限管理",
        subs: [
            {
                icon: "el-icon-odometer",
                index: "/account",
                title: "账号管理",
            },
            {
                icon: "el-icon-odometer",
                index: "/roles",
                title: "角色管理",
            },
            // {
            //     icon: "el-icon-odometer",
            //     index: "/authlist",
            //     title: "权限列表",
            // },
        ]
    },
    {

        icon: "el-icon-s-custom",
        index: "/channel",
        title: "渠道管理",
        subs: [
            {
                icon: "el-icon-odometer",
                index: "/channeldata",
                title: "渠道数据",
            },
            {
                icon: "el-icon-odometer",
                index: "/channelmanage",
                title: "渠道管理",
            },
        ]
    },
    // {
    //     icon: "el-icon-postcard",
    //     index: "/userlist",
    //     title: "渠道管理",
    //     subs:[
    //         {
    //             icon: "el-icon-postcard",
    //             index: "/userlist",
    //             title: "渠道用户",
    //         },
    //     ]
    // },
    {
        icon: "el-icon-goods",
        index: "/product",
        title: "商品管理",
        subs: [
            {
                icon: "el-icon-goods",
                index: "/productmanage",
                title: "商品管理",
            },
            // {
            //     icon: "el-icon-goods",
            //     index: "/goodsapply",
            //     title: "邀请码审核",
            // },
        ]
    },
    {
        icon: "el-icon-s-order",
        index: "/statement",
        title: "账户明细",
        subs: [
            {
                icon: "el-icon-goods",
                index: "/billing",
                title: "结算管理",
            },
            {
                icon: "el-icon-goods",
                index: "/deduction",
                title: "扣量管理",
            },
            {
                icon: "el-icon-goods",
                index: "/commission",
                title: "佣金明细",
            },
            // {
            //     icon: "el-icon-goods",
            //     index: "/statement",
            //     title: "分销提成",
            // },
            // {
            //     icon: "el-icon-goods",
            //     index: "/balance",
            //     title: "账户余额",
            // },
            // {
            //     icon: "el-icon-goods",
            //     index: "/withdraw",
            //     title: "账户提现",
            // },
        ]
    },
    {
        icon: "el-icon-s-order",
        index: "/announcement",
        title: "公告管理",
        // subs: [
        //     {
        //         icon: "el-icon-goods",
        //         index: "/announcement",
        //         title: "扣量管理",
        //     },
        // ]
    },
    {
        icon: "el-icon-s-order",
        index: "/sharepage",
        title: "分享页",
        // subs: [
        //     {
        //         icon: "el-icon-goods",
        //         index: "/announcement",
        //         title: "扣量管理",
        //     },
        // ]
    }
]