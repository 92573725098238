import axios from "axios";
import router from "../router/index";
import { Message } from 'element-ui';
import NProgress from 'nprogress/nprogress'

axios.defaults.withCredentials = false;

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 
    NProgress.start();
    // 在发送请求之前做些什么
    if (localStorage.getItem('idToken') && !config.url.includes('login')) {
        // console.log(localStorage.getItem('idToken'),'intercepors-14');
        config.headers.common['Authorization'] = localStorage.getItem('idToken')
    }

    config.headers.post['Content-Type'] = 'application/json'
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    NProgress.done();
    // console.log('response-success');
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
}, function (error) {
    // console.log(error.response, 'response-error');
    // 如果是因为网络错误原因就直接登出重新登录
    if (error == 'Error: Network Error') {
        router.push({ path: "/login" })
    }
    if (error.response.status !== undefined) {
        console.log(1);
        switch (error.response.status) {
            case 400:
                if (error.response.data.error.message === 'updating root password is not allowed') {
                    Message({
                        type: 'error',
                        message: '此账户不允许修改密码！'
                    })
                } else if (error.response.data.error.message === 'username exists') {
                    Message({
                        type: 'error',
                        message: '此用户名已存在！'
                    })
                } else { }
                break;

            default:
                break;
        }
        if (error.response.status === 400) {
            // router.push({ path: "/login" })

        }
        if (error.response.status === 401) {
            router.push({ path: "/login" })
        }
    }else{
        console.log(2);
        Message({
            type:'error',
            message:error
        })
    }

    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
});