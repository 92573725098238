import { http } from "../utils/http.js"

// let postSite = 'http://192.168.2.134:3002/api/v1'   //张伟 admin
// let postSite = 'https://cpsadmin.talku.vip/api/v1'   //张伟 admin(B端)测试服
let postSite = 'https://admin.distributorshub.com/api/v1'   //张伟 admin(B端)正式服




// login
export const _login = data => http.post(postSite, '/public/login', data)//用户登录
export const _getState = data => http.get(postSite, `/token/state`, data)
export const _postToken = data => http.post(postSite, `/token`, data)

//阿里云sts
export const _getSTS = data => http.get(postSite, `/token/sts`, data)

// 
export const _getProductOpStats = data => http.get(postSite, `/product_op_stats/statistics`, data)

// product 
export const _getProduct = data => http.get(postSite, `/product`)//获取应用列表   2022-05-11张伟
export const _postProduct = data => http.post(postSite, `/product`, data)//创建应用    2022-05-11张伟
export const _getProductByID = data => { let { ID } = data; return http.get(postSite, `/product/${ID}`) }//通过ID获取应用      2022-05-11张伟
export const _putProductByID = data => { let { ID } = data; return http.put(postSite, `/product/${ID}`, data) }//通过ID修改应用    2022-05-11张伟

// auth
export const _getPolicy = data => http.get(postSite, `/policy`)//获取权限菜单
export const _getRole = data => http.get(postSite, `/role`)//获取角色列表
export const _postRole = data => http.post(postSite, `/role`, data)//创建角色
export const _putRole = data => http.put(postSite, `/role`, data)//修改角色
export const _delRole = data => { let { ID } = data; return http.del(postSite, `/role/${ID}`, data) }//删除角色

// Announcement
export const _getAnnouncement = data => http.get(postSite, `/announcement`, data)
export const _postAnnouncement = data => http.post(postSite, `/announcement`, data)
export const _getAnnouncementByID = data => { let { ID } = data; return http.get(postSite, `/announcement/${ID}`, data) }
export const _putAnnouncement = data => { let { ID } = data; return http.put(postSite, `/announcement/${ID}`, data) }
export const _delAnnouncement = data => { let { ID } = data; return http.del(postSite, `/announcement/${ID}`, data) }

// account
export const _getAdmin = data => http.get(postSite, `/admin`, data)
export const _postAdmin = data => http.post(postSite, `/admin`, data)
export const _getAdminByID = data => { let { ID } = data; return http.get(postSite, `/admin/${ID}`, data) }
export const _putAdminByID = data => { let { ID } = data; return http.put(postSite, `/admin/${ID}`, data) }
export const _putAdminRoleByID = data => { let { ID } = data; return http.put(postSite, `/admin/${ID}/role`, data) }
export const _getAdminSelf = data => http.get(postSite, `/admin/self`, data)
export const _putAdminSelf = data => http.put(postSite, `/admin/self`, data)

// agent
export const _getAgent = data => http.get(postSite, `/agent`, data)
export const _postAgent = data => http.post(postSite, `/agent`, data)
export const _getAgentByID = data => { let { ID } = data; return http.get(postSite, `/agent/${ID}`, data) }
export const _putAgentByID = data => { let { ID } = data; return http.put(postSite, `/agent/${ID}`, data) }
export const _putAgentByIDApp = data => { let { ID } = data; return http.put(postSite, `/agent/${ID}/app`, data) }

// agent op stats
export const _getAgentOpStats = data => http.get(postSite, `/agent_op_stats`, data)
// sharePage
export const _getShareEnv = data => http.get(postSite, `/share_env`, data)
export const _getSharePage = data => http.get(postSite, `/share_page`, data)
export const _postSharePage = data => http.post(postSite, `/share_page`, data)
export const _putSharePage = data => { let { ID } = data; return http.put(postSite, `/share_page/${ID}`, data) }

// billing
export const _getBilling = data => http.get(postSite, `/billing`, data)
export const _putBilling = data => http.put(postSite, `/billing/settle`, data)

// deduction扣量管理
export const _getDeduction = data => http.get(postSite, `/recharge_record`, data)
export const _putDeduction = data => { let { ID } = data; return http.put(postSite, `/recharge_record/${ID}/deduct`, data) }

//佣金明细
export const _getCommission = data =>http.get(postSite, `/billing/detail`, data)
