<template>
  <article>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公告管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 新增按钮 -->
    <el-button
      @click="handleClick('add')"
      type="primary"
      style="margin-bottom: 20px"
      >新增公告</el-button
    >
    <!-- 表格 -->
    <el-table :data="announcementList" style="width: 100%" border>
      <el-table-column prop="ID" label="ID" width="100"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <!-- <el-table-column prop="content" label="内容">
                <template slot-scope="scope">
                    <span v-html="scope.row.content"></span>
                </template>
            </el-table-column> -->
      <el-table-column prop="createTime" label="创建时间" width="170">
        <template slot-scope="scope">
          {{ scope.row.createTime | dateFormat("YYYY-mm-dd HH:MM:SS") }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="175">
        <template slot-scope="scope">
          <!-- <el-button @click="handleClick('detail', scope.row)" type="primary">查看</el-button> -->
          <el-button @click="handleClick('update', scope.row)" type="warning"
            >修改</el-button
          >
          <el-button @click="handleClick('delete', scope.row)" type="danger"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 新增dialog -->
    <el-dialog
      title="新增"
      :visible.sync="addDialogFormVisible"
      width="50%"
      :before-close="handleClose"
      @close="resetForm('addForm')"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="是否置顶" prop="isTop">
          <el-select v-model="addForm.isTop" placeholder="请选择">
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道等级" prop="agentLevel">
          <el-select v-model="addForm.agentLevel" placeholder="请选择">
            <el-option label="所有" value="0"></el-option>
            <el-option label="一级代理" value="1"></el-option>
            <el-option label="二级代理" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="App" prop="appID">
          <el-select v-model="addForm.appID" placeholder="请选择">
            <el-option
              v-for="item in appOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="文本" prop="content">
          <vue-quill-editor
            v-if="addDialogFormVisible"
            :text="addForm.content"
            @updateText="addText"
          >
          </vue-quill-editor>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('addForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改dialog -->
    <el-dialog
      title="修改"
      :visible.sync="updateDialogFormVisible"
      width="50%"
      :before-close="handleClose"
      @close="resetForm('updateForm')"
    >
      <el-form
        :model="updateForm"
        :rules="updateFormRules"
        ref="updateForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="updateForm.title"></el-input>
        </el-form-item>
        <el-form-item label="是否置顶" prop="isTop">
          <el-select v-model="updateForm.isTop" placeholder="请选择">
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道等级" prop="agentLevel">
          <el-select v-model="updateForm.agentLevel" placeholder="请选择">
            <el-option label="所有" value="0"></el-option>
            <el-option label="一级代理" value="1"></el-option>
            <el-option label="二级代理" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="App" prop="appID">
          <el-select v-model="updateForm.appID" placeholder="请选择">
            <el-option
              v-for="item in appOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="文本" prop="content">
          <vue-quill-editor
            v-if="updateDialogFormVisible"
            :text="updateForm.content"
            @updateText="updateText"
          >
          </vue-quill-editor>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="debounce(submitForm('updateForm'))"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </article>
</template>

<script>
import {
  _getAnnouncement,
  _postAnnouncement,
  _getAnnouncementByID,
  _putAnnouncement,
  _delAnnouncement,
   _getProduct,
} from "../../api/index.js";
// import { appOptions } from "../../utils/common.js";
import vueQuillEditor from "../../components/textEditor/vueQuillEditor.vue";
import { parse } from "path";
// import { debounce } from "../../utils/common.js";
export default {
  name: "Announcement",
  components: { vueQuillEditor },

  data() {
    return {
      //   debounce: debounce,
      page: 1,
      pageSize: 10,
      total: 0,
      announcementList: [],
      appOptions: [{ value: "0", label: "全部" }],
      //
      addDialogFormVisible: false,
      updateDialogFormVisible: false,
      addForm: {
        title: "",
        appID: "0",
        agentLevel: "0", //0 代表所有 1级代理 2 二级代理
        isTop: false,
        content: "",
      },
      addFormRules: {
        title: [{ required: true, message: "请选择活动区域", trigger: "blur" }],
        content: [
          { required: true, message: "请选择活动区域", trigger: "blur" },
        ],
        appID: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        agentLevel: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        isTop: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
      },
      updateForm: {
        ID: "",
        title: "",
        appID: "0",
        agentLevel: "0", //0 代表所有 1级代理 2 二级代理
        isTop: false,
        content: "",
      },
      updateFormRules: {
        title: [{ required: true, message: "请选择活动区域", trigger: "blur" }],
        content: [
          { required: true, message: "请选择活动区域", trigger: "blur" },
        ],
        appID: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        agentLevel: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        isTop: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
      },
      productList:[],

    };
  },
  mounted() {
    this.getProductList();
    this.getAnnouncement();
  },
  methods: {
    // --------------------请求函数--------------------
    async getAnnouncement() {
      let res = await _getAnnouncement({
        page: this.page,
        pageSize: this.pageSize,
      });
      if (res && res.status === 200) {
        console.log(res.data.list);
        this.announcementList = res.data.list;
        this.total = res.data.total;
      }
    },
    async postAnnouncement() {
      let res = await _postAnnouncement({
        title: this.addForm.title,
        isTop: this.addForm.isTop,
        agentLevel: parseInt(this.addForm.agentLevel),
        appID: parseInt(this.addForm.appID),
        content: this.addForm.content,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res);
        this.getAnnouncement();
        this.addDialogFormVisible = false;
      }
    },
    async getAnnouncementByID(id) {
      let res = await _getAnnouncementByID({
        ID: id,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res.data);

        this.updateForm.ID = res.data.ID;
        this.updateForm.title = res.data.title;
        this.updateForm.content = res.data.content;
        this.updateForm.isTop = res.data.isTop ? res.data.isTop : false;
        this.updateForm.agentLevel = res.data.agentLevel
          ? res.data.agentLevel.toString()
          : "0";
        this.updateForm.appID = res.data.appID
          ? res.data.appID.toString()
          : "0";
        // console.log(this.updateForm);
        this.updateDialogFormVisible = true;
      }
    },
    async putAnnouncement() {
      let res = await _putAnnouncement({
        ID: this.updateForm.ID,
        title: this.updateForm.title,
        isTop: this.updateForm.isTop,
        agentLevel: parseInt(this.updateForm.agentLevel),
        appID: parseInt(this.updateForm.appID),
        content: this.updateForm.content,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        // console.log(res);
        this.getAnnouncement();
        this.updateDialogFormVisible = false;
      }
    },
    async delAnnouncement(id) {
      let res = await _delAnnouncement({
        ID: id,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        // console.log(res);
        this.getAnnouncement();
        this.updateDialogFormVisible = false;
      }
    },
    async getProductList() {
      let result = await _getProduct({});
      if (result && result.status === 200) {
        this.productList = result.data.list;
        console.log(this.productList);
        for (const key in this.productList) {
          let obj = {
            value: this.productList[key].ID,
            label: this.productList[key].name,
          };
          this.appOptions.push(obj);
        }
        console.log(this.appOptions, "options");
      }
    },
    // --------------------公共函数--------------------
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.page = 1;
      this.pageSize = val;
      this.getAnnouncement();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getAnnouncement();
    },
    handleClose(done) {
      this.$messagebox
        .confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // --------------------处理函数--------------------
    handleClick(type, e) {
      // console.log(e,"打印参数");
      switch (type) {
        case "add":
          console.log("新增");
          this.addDialogFormVisible = true;
          break;
        case "detail":
          break;

        case "update":
          console.log(e.ID, "修改");
          this.getAnnouncementByID(e.ID);
          break;
        case "delete":
          console.log(e.ID, "删除");
          // this.delAnnouncement(e.ID)
          this.$messagebox
            .confirm("是否要删除该条公告？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              this.delAnnouncement(e.ID);
            })
            .catch(() => {});
          break;

        default:
          break;
      }
    },
    //
    addText(text) {
      this.addForm.content = text;
    },
    updateText(text) {
      this.updateForm.content = text;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addForm":
              console.log(this.addForm, "提交新表单");
              this.postAnnouncement();

              break;
            case "updateForm":
              console.log(this.updateForm, "修改表单");
              this.putAnnouncement();
              break;

            default:
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>