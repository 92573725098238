<template>
  <article>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>渠道数据</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询参数 -->
    <el-form :inline="true" :model="queryInfo" size="small">
      <el-form-item label="渠道ID：">
        <el-input v-model="queryInfo.agentID"></el-input>
      </el-form-item>
      <el-form-item label="渠道类型：">
        <el-select v-model="queryInfo.agentLevel" style="width: 100px">
          <el-option label="全部渠道" value="0"></el-option>
          <el-option label="一级渠道" value="1"></el-option>
          <el-option label="二级渠道" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品类型：">
        <el-select v-model="queryInfo.appID" style="width: 100px">
          <el-option
            v-for="item in appOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序条件筛选：">
        <el-select v-model="queryInfo.amountOrder">
          <el-option label="无" value="0"></el-option>
          <el-option label="充值金额-降序" value="1"></el-option>
          <el-option label="充值金额-升序" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间选择：">
        <el-date-picker
          v-model="queryInfo.timeRange"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleClick('select')"
          >查 询</el-button
        >
        <el-button type="primary" @click="handleClick('resetSelect')"
          >重 置</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="agentOpStatsList" style="width: 100%" border>
      <el-table-column
        prop="date"
        label="时间"
        width="110"
        v-if="queryInfo.timeRange === null"
      ></el-table-column>
      <el-table-column label="时间" width="180" v-else>
        {{ dateFormat(queryInfo.timeRange[0], "YYYY-mm-dd") }}---{{
          dateFormat(queryInfo.timeRange[1], "YYYY-mm-dd")
        }}
      </el-table-column>
      <el-table-column prop="appID" label="appID"></el-table-column>
      <el-table-column prop="agentID" label="渠道ID"></el-table-column>
      <el-table-column prop="parentAgentID" label="绑定人"></el-table-column>
      <el-table-column prop="UV" label="访问量"></el-table-column>
      <el-table-column prop="download" label="下载量"></el-table-column>
      <el-table-column prop="DAU" label="日活"></el-table-column>
      <el-table-column prop="DNUAndroid" label="安卓新增"></el-table-column>
      <el-table-column prop="DNUIos" label="IOS新增"></el-table-column>
      <!-- <el-table-column prop="DNUH5" label="H5新增"></el-table-column> -->
      <el-table-column prop="goldAmount" label="金币充值"></el-table-column>
      <el-table-column prop="vipAmount" label="VIP充值"></el-table-column>
      <el-table-column prop="goldCommission" label="金币佣金"></el-table-column>
      <el-table-column prop="vipCommission" label="VIP佣金"></el-table-column>
      <el-table-column
        prop="inviteAgentRewards"
        label="下级代理邀请奖励"
      ></el-table-column>
      <el-table-column
        prop="inviteUserSubsidy"
        label="注册补贴"
      ></el-table-column>
      <el-table-column
        prop="childGoldCommission"
        label="下级金币抽成"
      ></el-table-column>
      <el-table-column
        prop="childVipCommission"
        label="下级VIP抽成"
      ></el-table-column>

      <!-- <el-table-column prop="remarks" label="备注"></el-table-column>
            <el-table-column prop="touch" label="触达次数"></el-table-column>
            <el-table-column prop="click" label="点击次数"></el-table-column>
            <el-table-column prop="registrations" label="新增注册(安卓/苹果/H5)"></el-table-column>
            <el-table-column prop="click" label="新增充值人数(vip/金币)"></el-table-column>
            <el-table-column prop="click" label="新增充值金额(vip/金币)"></el-table-column>
            <el-table-column prop="click" label="充值总人数(vip/金币)"></el-table-column>
            <el-table-column prop="click" label="充值总金额(vip/金币)"></el-table-column>
            <el-table-column prop="click" label="分成比例"></el-table-column>
            <el-table-column prop="click" label="扣量设置"></el-table-column>
            <el-table-column prop="click" label="下级分成"></el-table-column>
            <el-table-column prop="click" label="佣金"></el-table-column> -->
      <!-- <el-table-column label="操作"></el-table-column> -->
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryInfo.total"
    >
    </el-pagination>
  </article>
</template>

<script>
import { _getAgentOpStats } from "../../api/index.js";
import { appOptions } from "../../utils/common.js";

export default {
  name: "ChannelData",
  data() {
    return {
      queryInfo: {
        page:1,
        pageSize:10,
        total:0,
        agentLevel: "0",
        appID: "0",
        agentID: "",
        amountOrder: "0",
        // timeRange: [
        //   new Date(
        //     new Date(new Date().setHours(0, 0, 0, 0)).getTime() -
        //       3600 * 24 * 1000 * 6
        //   ),
        //   new Date(
        //     new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
        //       3600 * 24 * 1000
        //   ),
        // ],
        timeRange: null,
      },
      appOptions: appOptions,
      agentOpStatsList: [],
    };
  },
  mounted() {
    this.getAgentOpstats();
  },
  methods: {
    // --------------------请求函数--------------------
    async getAgentOpstats() {
      console.log(this.queryInfo.timeRange !== null);
      let res = await _getAgentOpStats({
        page: this.queryInfo.page,
        pageSize: this.queryInfo.pageSize,
        agentID:
          this.queryInfo.agentID === ""
            ? "0"
            : parseInt(this.queryInfo.agentID),
        agentLevel: parseInt(this.queryInfo.agentLevel),
        appID: parseInt(this.queryInfo.appID),
        amountOrder: parseInt(this.queryInfo.amountOrder),
        startDate:
          this.queryInfo.timeRange !== null
            ? this.dateFormat(this.queryInfo.timeRange[0], "YYYY-mm-dd")
            : 0,
        endDate:
          this.queryInfo.timeRange !== null
            ? this.dateFormat(this.queryInfo.timeRange[1], "YYYY-mm-dd")
            : 0,
      });
      if (res && res.status === 200) {
        console.log(res.data.list);
        this.agentOpStatsList = res.data.list;
        this.queryInfo.total =res.data.total
      }
    },

    // --------------------处理函数--------------------
    handleClick(type, e) {
      // console.log(e,"打印参数");
      switch (type) {
        case "select":
          console.log(this.queryInfo, "打印查询参数");
          this.getAgentOpstats();
          break;
        case "resetSelect":
          console.log("重置查询参数并请求");
          this.queryInfo = {
            agentLevel: "0",
            appID: "0",
            amountOrder: "0",
            agentID: "",
            timeRange: null,
          };
          this.getAgentOpstats();
          break;

        default:
          break;
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.queryInfo.pageSize = val;
      this.queryInfo.page = 1;
      this.getAgentOpstats();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryInfo.page = val;
      this.getAgentOpstats();
    },
    dateFormat(date, fmt) {
      // ----------临时处理----------0
      // 区分后端传的是毫秒还是秒
      // let str = date + ''
      // if(str.length <= "10"){
      //     date= date*1000
      // }
      // ----------临时处理----------1
      // if(isNaN(date)){
      //     return '1970-01-01 08:00:00'
      // }
      let ret = "";
      date = new Date(date);

      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
};
</script>