<template>
  <article>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>账户列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-button @click="handleClick('newAccountDialog')" type="primary" size="mini" style="margin-bottom: 10px">
      创建账号
    </el-button>

    <el-table :data="accountList" border stripe style="width: 100%">
      <el-table-column prop="ID" label="ID" width="80"></el-table-column>
      <el-table-column prop="nickname" label="账户名称"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="160">
        <template slot-scope="scope">
          <span>{{
              scope.row.createTime | dateFormat("YYYY-mm-dd HH:MM:SS")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" width="160">
        <template slot-scope="scope">
          <span>{{
              scope.row.updateTime | dateFormat("YYYY-mm-dd HH:MM:SS")
          }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="status" label="账号状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0" style="color: #909399">未激活</span>
          <span v-if="scope.row.status === 1" style="color: #67c23a">启用</span>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <el-button @click="handleClick('update1', scope.row)" type="primary"  v-if="scope.row.ID !== 2">修改昵称</el-button>
          <el-button @click="handleClick('update2', scope.row)" type="primary" v-if="scope.row.ID !== 2">修改角色信息</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize"
      layout="total,  prev, pager, next, jumper" :total="total">
    </el-pagination>
    <!-- 新建账户 -->
    <el-dialog title="新建账户" :visible.sync="addDialogFormVisible" :before-close="handleClose"
      @closed="closeDialog('addForm')">
      <el-form :model="addForm" :rules="addFormRules" ref="addForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="登录账户：" prop="account">
          <el-input v-model="addForm.account"></el-input>
        </el-form-item>
        <el-form-item label="登录密码：" prop="password">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="账户昵称：" prop="nickname">
          <el-input v-model="addForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="禁用账户：" prop="banned">
          <el-select v-model="addForm.banned">
            <el-option label="开启禁用" :value="true"></el-option>
            <el-option label="关闭禁用" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户角色" prop="roleArray">
          <el-select v-model="addForm.roleArray" multiple>
            <el-option v-for="item in rolesOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('addForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改昵称 -->
    <el-dialog title="修改昵称" :visible.sync="nicknameDialogFormVisible" :before-close="handleClose"
      @closed="closeDialog('nicknameForm')">
      <el-form :model="nicknameForm" :rules="nicknameFormRules" ref="nicknameForm" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="修改昵称：" prop="nickname">
          <el-input v-model="nicknameForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="禁用账户：" prop="banned">
          <el-select v-model="nicknameForm.banned">
            <el-option label="开启禁用" :value="true"></el-option>
            <el-option label="关闭禁用" :value="false"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="nicknameDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('nicknameForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新建账户 -->
    <el-dialog title="修改角色" :visible.sync="rolesDialogFormVisible" :before-close="handleClose"
      @closed="closeDialog('roleForm')">
      <el-form :model="roleForm" :rules="roleFormRules" ref="roleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="账户角色" prop="roleArray">
          <el-select v-model="roleForm.roleArray" multiple>
            <el-option v-for="item in rolesOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="rolesDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('roleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </article>
</template>

<script>
import { _getAdmin, _postAdmin, _getRole, _getAdminByID, _putAdminByID, _putAdminRoleByID } from "../../api/index.js";

export default {
  name: "Account",
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      queryInfo: {
        account: '',
      },
      accountList: [],
      // ----------dialog----------
      addDialogFormVisible: false,
      nicknameDialogFormVisible: false,
      rolesDialogFormVisible: false,
      // addForm
      addForm: {
        account: 'account',
        password: '123456',
        nickname: 'nickname',
        banned: false,
        roleArray: [],
        roleRefs: [],
      },
      addFormRules: {
        account: [{ required: true, message: "请输入登录账户", trigger: "blur" }],
        password: [{ required: true, message: "请输入登录密码", trigger: "blur" }],
        nickname: [{ required: true, message: "请输入账户昵称", trigger: "blur" }],
        roleArray: [{ required: true, message: "请选择账户角色", trigger: "change" }],
      },
      // updateNickname
      nicknameForm: {
        ID: '',
        nickname: '',
        banned: false
      },
      nicknameFormRules: {
        nickname: [{ required: true, message: "请输入账户昵称", trigger: "blur" }],
      },
      // roleForm
      roleForm: {
        ID: '',
        roleArray: []
      },
      roleFormRules: {
        nickname: [{ required: true, message: "请输入登录账户", trigger: "blur" }],
      },
      rolesList: [],
      rolesOptions: [],
      //

    };
  },
  mounted() {
    this.getAdmin()
    this.getRole()
  },
  methods: {
    // --------------------请求函数--------------------
    // 账户列表查询
    async getAdmin() {
      let result = await _getAdmin({
        account: this.queryInfo.account,
        page: this.page,
        pageSize: this.pageSize,
      });
      if (result && result.status === 200) {
        console.log(result.data, '账户列表');
        this.total = result.data.total
        this.accountList = result.data.list
      }
    },
    // 获取角色列表
    async getRole() {
      let res = await _getRole({})
      if (res && res.status === 200) {
        // console.log(res.data.roles);
        this.rolesList = res.data.roles
        let arr = []
        res.data.roles.map(item => {
          // console.log(item);
          if (item.type !== 'system') {
            // system不可被创建
            let t = { value: item.ID, label: item.name }
            arr.push(t)
          }
        })
        this.rolesOptions = arr
        console.log(this.rolesOptions, '角色下拉列表');
      }
    },
    // 创建新账户
    async postAdmin() {
      let res = await _postAdmin({
        account: this.addForm.account,
        password: this.addForm.password,
        admin: {
          nickname: this.addForm.nickname,
          banned: this.addForm.banned,
          roleRefs: this.addForm.roleRefs,
        }
      })
      if (res && (res.status === 200 || res.status === 204)) {
        this.getAdmin()
        this.addDialogFormVisible = false
      }
    },
    // 获取账户信息（是否带角色信息）
    async getAdminByID(id, withRoles) {
      let res = await _getAdminByID({
        ID: id,
        withRoles: withRoles
      })
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res.data);
        this.nicknameForm.ID = res.data.ID
        this.nicknameForm.nickname = res.data.nickname
        if (res.data.banned) {
          this.nicknameForm.banned = res.data.banned
        }
        if (withRoles) {
          let arr = []
          for (const iterator of res.data.roleRefs) {
            arr.push(iterator.ID)
          }
          res.data.roleArray = arr
          this.roleForm.ID = res.data.ID
          this.roleForm.roleArray = res.data.roleArray
        }
      }
      console.log(this.nicknameForm, 'nicknameForm');
      console.log(this.roleForm, 'roleForm');
    },
    // 修改账户昵称（基础信息）
    async putAdminByID() {
      let res = await _putAdminByID({
        ID: parseInt(this.nicknameForm.ID),
        nickname: this.nicknameForm.nickname,
        banned:this.nicknameForm.banned
      })
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res.data);
        this.getAdmin()
        this.nicknameDialogFormVisible = false
      }
    },
    // 
    async putAdminRole() {
      let res = await _putAdminRoleByID({
        ID: this.roleForm.ID,
        roles: this.roleForm.roleArray,
      })
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res.data);
        this.getAdmin()
        this.$message.success('修改成功')
        this.rolesDialogFormVisible = false
      }
    },
    // --------------------点击函数--------------------
    // type--->按钮类型 e--->按钮传值
    handleClick(type, e) {
      switch (type) {
        case "newAccountDialog":
          this.addDialogFormVisible = true;
          break;

        case "update1":
          console.log(e.ID, "修改");
          this.getAdminByID(e.ID, false)
          this.nicknameDialogFormVisible = true
          break;
        case "update2":
          console.log(e.ID, "修改");
          this.getAdminByID(e.ID, true)
          this.rolesDialogFormVisible = true
          break;

        default:
          break;
      }
    },
    // --------------------表单提交--------------------
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addForm":
              console.log(this.addForm, "提交表单");
              // 根据roleArray生成roleRefs
              for (const iterator of this.rolesList) {
                // console.log(iterator);
                if (this.addForm.roleArray.includes(iterator.ID) && iterator.rules) {
                  // console.log(thi);
                  let t = {
                    ID: iterator.ID,
                    name: iterator.name,
                    rules: iterator.rules
                  }
                  this.addForm.roleRefs.push(t)
                }
              }
              this.postAdmin()
              break;
            case "nicknameForm":
              console.log(this.nicknameForm);
              this.putAdminByID()
              break;
            case "roleForm":
              console.log(this.roleForm);
              this.putAdminRole()
              break;

            default:
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // --------------------公共函数--------------------
    // 关闭dialog清除数据
    closeDialog(formName) {
      this.$refs[formName].resetFields();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleClose(done) {
      this.$messagebox.confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },
  },
};
</script>

<style scope>
</style>

