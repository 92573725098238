<template>
  <article>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>概况</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  -->
    <el-form :inline="true" :model="queryInfo" size="small">
      <el-form-item label="时间选择：">
        <!-- 带快捷选项时间筛选 -->
        <el-date-picker
          v-model="queryInfo.timeRange"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleClick('select')"
          >查 询</el-button
        >
        <el-button type="primary" @click="handleClick('resetSelect')"
          >重 置</el-button
        >
      </el-form-item>
    </el-form>

    <!-- 推广概况表格 -->
    <div class="title">推广概况</div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="appID" label="appID"></el-table-column>
      <el-table-column prop="appName" label="appName"></el-table-column>
      <el-table-column prop="" label="新增渠道"></el-table-column>
      <el-table-column prop="" label="活跃渠道"></el-table-column>
      <el-table-column prop="UV" label="独立访问量(UV)"></el-table-column>
      <el-table-column prop="download" label="下载次数"></el-table-column>
      <el-table-column  label="总注册">
        <template slot-scope="scope">
           <span v-if="scope.row.DNUAndroid && scope.row.DNUAndroid + scope.row.DNUIos">
            {{scope.row.DNUAndroid + scope.row.DNUIos}}
           </span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="DAU" label="日活"></el-table-column> -->
      <!-- <el-table-column prop="DNUAndroid" label="Android新增"></el-table-column> -->
      <!-- <el-table-column prop="DNUIos" label="IOS新增"></el-table-column> -->
      <!-- <el-table-column prop="DNUH5" label="H5新增"></el-table-column> -->

      <el-table-column
        prop="newGoldUser"
        label="新增金币充值人数"
      ></el-table-column>
      <el-table-column
        prop="newVipUser"
        label="新增vip充值人数"
      ></el-table-column>
      <el-table-column
        prop="oldGoldUser"
        label="留存金币充值人数"
      ></el-table-column>
      <el-table-column
        prop="oldVipUser"
        label="留存vip充值人数"
      ></el-table-column>
    </el-table>
    <!-- 充值概况表格 -->
    <div class="title">充值概况</div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="appID" label="appID"></el-table-column>
      <el-table-column prop="appName" label="appName"></el-table-column>
      <el-table-column
        prop="newGoldUser"
        label="新增充值金额(vip/金币)"
      ></el-table-column>
      <el-table-column
        prop="oldGoldUser"
        label="留存充值金额(vip/金币)"
      ></el-table-column>
      <el-table-column
        prop="newVipUser"
        label="新增充值人数(vip/金币)"
      ></el-table-column>
      <el-table-column
        prop="oldVipUser"
        label="留存充值人数(vip/金币)"
      ></el-table-column>
    </el-table>
    <!-- 运营概况概况表格 -->
    <div class="title">运营概况</div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="productName" label="产品"></el-table-column>
      <el-table-column
        prop="productName"
        label="总注册(安卓/苹果)"
      ></el-table-column>
      <el-table-column
        prop="productName"
        label="总充值(vip/金币)"
      ></el-table-column>
      <el-table-column
        prop="productName"
        label="总充值人数(vip/金币)"
      ></el-table-column>
      <el-table-column prop="productName" label="新增arpu"></el-table-column>
      <el-table-column prop="productName" label="新增arppu"></el-table-column>
      <el-table-column prop="productName" label="arppu"></el-table-column>
      <el-table-column prop="productName" label="付费率"></el-table-column>
      <el-table-column prop="productName" label="DAU"></el-table-column>
    </el-table>
    <!--  -->
    <div class="title">渠道排行榜</div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="productName" label="产品"></el-table-column>
      <el-table-column prop="productName" label="模式"></el-table-column>
      <el-table-column prop="productName" label="渠道ID"></el-table-column>
      <el-table-column prop="productName" label="渠道级别"></el-table-column>
      <el-table-column prop="productName" label="下级数量"></el-table-column>
      <el-table-column prop="productName" label="上级渠道ID"></el-table-column>
      <el-table-column prop="productName" label="所属商务"></el-table-column>
      <el-table-column prop="productName" label="备注"></el-table-column>
      <el-table-column prop="productName" label="充值金额"></el-table-column>
    </el-table>
  </article>
</template>

<script>
import { _getProductOpStats } from "../api/index.js";
export default {
  name: "DashBoard",
  data() {
    return {
      // 查询参数
      queryInfo: {
        timeRange: [
          new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).getTime() -
              3600 * 24 * 1000 * 6
          ),
          new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
              3600 * 24 * 1000
          ),
        ],
      },
      // 时间查询options
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [],
    };
  },
  mounted() {
    this.getProductOpStats();
  },
  methods: {
    // --------------------请求函数--------------------
    async getProductOpStats() {
      let res = await _getProductOpStats({
        startDate: this.dateFormat(this.queryInfo.timeRange[0], "YYYY-mm-dd"),
        endDate: this.dateFormat(this.queryInfo.timeRange[1], "YYYY-mm-dd"),
      });
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res.data.list);
        this.tableData = res.data.list;
      }
    },
    dateFormat(date, fmt) {
      let ret = "";
      date = new Date(date);

      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    // --------------------处理函数--------------------
    handleClick(type, e) {
      // console.log(e,"打印参数");
      switch (type) {
        case "select":
          console.log(this.queryInfo, "打印查询参数");
          if (this.queryInfo.timeRange === null) {
            this.queryInfo.timeRange = [
              new Date(
                new Date(new Date().setHours(0, 0, 0, 0)).getTime() -
                  3600 * 24 * 1000 * 6
              ),
              new Date(
                new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
                  3600 * 24 * 1000
              ),
            ];
          }
          this.getProductOpStats();
          break;
        case "resetSelect":
          console.log("重置查询参数并请求");
          this.queryInfo.timeRange = [
            new Date(
              new Date(new Date().setHours(0, 0, 0, 0)).getTime() -
                3600 * 24 * 1000 * 6
            ),
            new Date(
              new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
                3600 * 24 * 1000
            ),
          ];
          this.getProductOpStats();
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scope>
.title {
  font-size: 1.17em;
  font-weight: 700;
  border-top: 1px solid black;
  padding: 20px 0;
}
.el-table {
  margin-bottom: 70px;
}
</style>

