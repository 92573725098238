<template>
  <article>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>渠道管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询参数 -->
    <el-form :inline="true" :model="queryInfo" size="small">
      <el-form-item label="渠道ID：">
        <el-input v-model="queryInfo.ID"></el-input>
      </el-form-item>
      <el-form-item label="渠道类型：">
        <el-select v-model="queryInfo.level" style="width: 100px">
          <el-option label="全部渠道" value="0"></el-option>
          <el-option label="一级渠道" value="1"></el-option>
          <el-option label="二级渠道" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品类型：">
        <el-select v-model="queryInfo.appID" style="width: 100px">
          <el-option
            v-for="item in appOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="queryInfo.state">
          <el-option label="全部" value="0"></el-option>
          <el-option label="正常" value="1"></el-option>
          <el-option label="封禁" value="2"></el-option>
          <el-option label="未激活" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间选择：">
        <el-date-picker
          v-model="queryInfo.timeRange"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleClick('select')">
          查 询
        </el-button>
        <el-button type="primary" @click="handleClick('resetSelect')">
          重 置
        </el-button>
        <el-button type="success" @click="handleClick('add')">
          新增渠道
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="channelList" style="width: 100%" border>
      <el-table-column prop="agent.agent.ID" label="渠道ID"></el-table-column>
      <el-table-column prop="parentName" label="绑定人"></el-table-column>
      <el-table-column
        prop="agent.agent.account"
        label="登录账号"
      ></el-table-column>
      <el-table-column
        prop="agent.agent.lastLoginTime"
        label="最近登录时间"
        width="170"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.agent.agent.lastLoginTime">
            {{
              scope.row.agent.agent.lastLoginTime
                | dateFormat("YYYY-mm-dd HH:MM:SS")
            }}
          </span>
          <span v-else> 服务器暂无记录 </span>
        </template>
      </el-table-column>
      <el-table-column prop="agent.agent.level" label="渠道等级">
        <template slot-scope="scope">
          <span v-if="scope.row.agent.agent.level == 1">一级渠道</span>
          <span v-if="scope.row.agent.agent.level == 2">二级渠道</span>
        </template>
      </el-table-column>
      <el-table-column prop="agent.agent.state" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.agent.agent.state == 1">正常</span>
          <span v-if="scope.row.agent.agent.state == 2">封禁</span>
          <span v-if="scope.row.agent.agent.state == 3">未激活</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="380">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="handleClick('channlDetail', scope.row)"
            >查看</el-button
          >
          <el-button
            v-if="scope.row.agent.agent.level === 1"
            type="primary"
            @click="handleClick('update1', scope.row)"
            >修改基础信息</el-button
          >
          <el-button
            v-if="scope.row.agent.agent.level === 1"
            type="primary"
            @click="handleClick('update2', scope.row)"
            >修改渠道产品</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- add-dialog -->
    <el-dialog
      title="新增渠道"
      :visible.sync="addChannelDialogFormVisible"
      :before-close="handleClose"
      @closed="resetForm('addChannelForm')"
    >
      <el-form
        :model="addChannelForm"
        :rules="addChannelFormRules"
        ref="addChannelForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="代理账号" prop="account">
              <el-input v-model="addChannelForm.account"></el-input>
            </el-form-item>
            <el-form-item label="代理备注" prop="remark">
              <el-input v-model="addChannelForm.remark"></el-input>
            </el-form-item>
            <el-form-item label="代理密码" prop="password">
              <el-input v-model="addChannelForm.password"></el-input>
            </el-form-item>
            <el-form-item label="激活状态" prop="state">
              <el-select
                v-model="addChannelForm.state"
                placeholder="请选择状态"
              >
                <el-option label="正常" value="1"> </el-option>
                <el-option label="封禁" value="2"> </el-option>
                <el-option label="未激活" value="3"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <hr />
        <!-- <div v-for="item in productList" :key="item.ID"> -->
        <div v-for="item in addChannelForm.app" :key="item.ID">
          <div style="padding: 10px 5px">
            产品名称：{{ item.name }}
            <span style="margin-left: 40px">是否激活产品:</span>
            <el-select v-model="item.status" placeholder="请选择">
              <el-option label="开启" value="1"> </el-option>
              <el-option label="关闭" value="2"> </el-option>
            </el-select>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Vip分成">
                <el-input v-model="item.vipProp"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="金币分成">
                <el-input v-model.number="item.goldProp"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="padding-bottom: 10px">
            扣量设置：默认规定前100笔充值订单扣量配置不生效
          </div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="vip订单满" prop="vip">
                <el-input v-model="item.vip"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="扣1笔订单" prop="coin" label-width="80px">
                <el-switch
                  v-model="item.switch1"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="金币订单满" prop="coin">
                <el-input v-model="item.coin"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="扣1笔订单" prop="coin" label-width="80px">
                <el-switch
                  v-model="item.switch2"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <hr />
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addChannelDialogFormVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="submitForm('addChannelForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看dialog -->
    <el-dialog
      title="渠道信息"
      :visible.sync="channelDetailDialogFormVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form
            ref="channelDetailForm"
            :model="channelDetailForm"
            label-width="120px"
          >
            <el-form-item label="渠道ID">
              <el-input
                v-model="channelDetailForm.agent.agent.ID"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="渠道类型">
              <el-input
                v-model="channelDetailForm.agent.agent.level"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="渠道用户名">
              <el-input
                v-model="channelDetailForm.channelName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-input
                v-if="channelDetailForm.agent.agent.state === 3"
                value="待激活"
                disabled
              ></el-input>
              <el-input
                v-if="channelDetailForm.agent.agent.state === 1"
                value="正常"
                disabled
              ></el-input>
              <el-input
                v-if="channelDetailForm.agent.agent.state === 2"
                value="已锁定"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="下级渠道数">
              <el-input
                v-model="channelDetailForm.childCount"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="待提现金额">
              <el-input
                v-model="channelDetailForm.withdraw_0"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="审核中金额">
              <el-input
                v-model="channelDetailForm.withdraw_1"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="已支付金额">
              <el-input
                v-model="channelDetailForm.withdraw_2"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="已拒绝金额">
              <el-input
                v-model="channelDetailForm.withdraw_3"
                disabled
              ></el-input>
            </el-form-item>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="所属商务">
                  <el-input
                    v-model="channelDetailForm.agent.agent.businessUserID"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属部门">
                  <el-input
                    v-model="channelDetailForm.group"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="注册时间">
              <el-input
                v-model="channelDetailForm.registrationTime"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="最后登录时间">
              <el-input
                v-model="channelDetailForm.recentlyTime"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="累计登录次数">
              <el-input
                v-model="channelDetailForm.totolLoginTimes"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form
            ref="channelInfoForm"
            :model="channelInfoForm"
            label-width="120px"
          >
            <el-form-item label="邮箱">
              <el-input v-model="channelInfoForm.email" disabled></el-input>
            </el-form-item>
            <el-form-item label="QQ">
              <el-input v-model="channelInfoForm.QQ" disabled></el-input>
            </el-form-item>
            <el-form-item label="微信">
              <el-input v-model="channelInfoForm.weixin" disabled></el-input>
            </el-form-item>
            <el-form-item label="WhatsApp">
              <el-input v-model="channelInfoForm.whatsapp" disabled></el-input>
            </el-form-item>
            <el-form-item label="Line">
              <el-input v-model="channelInfoForm.line" disabled></el-input>
            </el-form-item>
            <el-form-item label="Telegram">
              <el-input v-model="channelInfoForm.telegram" disabled></el-input>
            </el-form-item>
            <el-form-item label="收款类型">
              <el-input v-model="channelInfoForm.payType" disabled></el-input>
            </el-form-item>
            <el-form-item label="收款姓名">
              <el-input v-model="channelInfoForm.payName" disabled></el-input>
            </el-form-item>
            <el-form-item label="收款账号">
              <el-input
                v-model="channelInfoForm.payAccount"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="channelDetailForm.agent.agent.remark"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="下级数量限制">
              <el-input v-model="channelInfoForm.limit" disabled></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="channelDetailDialogFormVisible = false"
          >取 消</el-button
        >
        <el-button @click="channelDetailDialogFormVisible = false"
          >登录后台</el-button
        > -->
        <el-button
          type="primary"
          @click="channelDetailDialogFormVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改渠道基础信息dialog -->
    <el-dialog
      title="修改渠道基础信息"
      :visible.sync="update1DialogFormVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form
        :model="updateForm1"
        :rules="updateForm1Rules"
        ref="updateForm1"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="代理备注" prop="remark">
          <el-input v-model="updateForm1.remark"></el-input>
        </el-form-item>
        <el-form-item label="激活状态" prop="state">
          <el-select v-model="updateForm1.state" placeholder="请选择状态">
            <el-option label="正常" value="1"> </el-option>
            <el-option label="封禁" value="2"> </el-option>
            <el-option label="未激活" value="3"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="update1DialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('updateForm1')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改渠道产品dialog -->
    <el-dialog
      title="修改渠道产品"
      :visible.sync="update2DialogFormVisible"
      :before-close="handleClose"
      @closed="resetForm('updateForm2')"
    >
      <el-form
        :model="updateForm2"
        :rules="updateForm2Rules"
        ref="updateForm2"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="代理账号" prop="account">
              <el-input v-model="addChannelForm.account"></el-input>
            </el-form-item>
            <el-form-item label="代理备注" prop="remark">
              <el-input v-model="addChannelForm.remark"></el-input>
            </el-form-item>
            <el-form-item label="代理密码" prop="password">
              <el-input v-model="addChannelForm.password"></el-input>
            </el-form-item>
            <el-form-item label="激活状态" prop="state">
              <el-select
                v-model="addChannelForm.state"
                placeholder="请选择状态"
              >
                <el-option label="正常" value="1"> </el-option>
                <el-option label="封禁" value="2"> </el-option>
                <el-option label="未激活" value="3"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <hr /> -->
        <!-- <div v-for="(item,index) in productList" :key="item.ID"> -->
        <div v-for="(item, index) in updateForm2.app" :key="item.ID">
          <div style="padding: 10px 5px">
            产品名称：{{ item.base.name }}
            <span style="margin-left: 40px">是否激活产品:</span>
            <el-select v-model="item.status" placeholder="请选择">
              <el-option label="开启" value="1"> </el-option>
              <el-option label="关闭" value="2"> </el-option>
            </el-select>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="Vip分成"
                :prop="'app.' + index + '.vipProp'"
                :rules="{
                  validator: vipRules,
                  trigger: 'blur',
                }"
              >
                <el-input v-model="item.vipProp"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="金币分成"
                :prop="'app.' + index + '.goldProp'"
                :rules="{
                  validator: coinRules,
                  trigger: 'blur',
                }"
              >
                <el-input v-model="item.goldProp"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="padding-bottom: 10px">
            扣量设置：默认规定前100笔充值订单扣量配置不生效
          </div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="vip订单满" prop="vip">
                <el-input v-model.number="item.vip"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="扣1笔订单" prop="coin" label-width="80px">
                <el-switch
                  v-model="item.switch1"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="金币订单满" prop="coin">
                <el-input v-model.number="item.coin"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="扣1笔订单" prop="coin" label-width="80px">
                <el-switch
                  v-model="item.switch2"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <hr />
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="update2DialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('updateForm2')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </article>
</template>

<script>
import {
  _getAgent,
  _postAgent,
  _getProduct,
  _getAgentByID,
  _putAgentByID,
  _putAgentByIDApp,
} from "../../api/index.js";
export default {
  name: "ChannelData",

  data() {
    var vipRules = (rule, value, callback) => {
      if (value == "") {
        callback();
      } else if (!parseInt(value)) {
        console.log(value, "11");
        callback(new Error("请输入数字!"));
      } else if (parseInt(value) > 90 || parseInt(value) < 0) {
        callback(new Error("Vip最大分成比例为90%!"));
      } else {
        callback();
      }
    };
    var coinRules = (rule, value, callback) => {
      if (value == "") {
        callback();
      } else if (!parseInt(value)) {
        callback(new Error("请输入数字!"));
      } else if (parseInt(value) > 40 || parseInt(value) < 0) {
        callback(new Error("金币最大分成比例为40%!"));
      } else {
        callback();
      }
    };
    return {
      uuid: parseInt(sessionStorage.getItem("uuid")),
      vipRules: vipRules,
      coinRules: coinRules,
      page: 1,
      pageSize: 10,
      total: 0,
      queryInfo: {
        ID: "",
        appID: "0",
        level: "0",
        state: "0", // 1 正常 2封禁 3 未激活
        timeRange: [
          new Date(new Date("1970-01-01 00:00:00")),
          new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
              3600 * 24 * 1000
          ),
        ],
      },
      appOptions: [{ label: "全部", value: "0" }],
      // --------------------table--------------------
      channelList: [], //渠道列表
      productList: [], //产品列表

      // --------------------dialog--------------------
      addChannelDialogFormVisible: false, //新增渠道dialog
      channelDetailDialogFormVisible: false, //查看渠道dialog
      update1DialogFormVisible: false, //修改基础信息dialog
      update2DialogFormVisible: false, //修改产品信息dialog
      // --------------------新增渠道form--------------------
      addChannelForm: {
        account: "", //代理账号
        remark: "", //代理备注
        password: "", //代理密码
        state: "3", //1 正常 2封禁 3 未激活

        app: [
          {
            ID: 0,
            vipProp: 0, //会员分成比例
            goldProp: 0, //金币分成比例
            billingMode: 1, //分成模式 1 cps
            status: 1, //开通状态 1：正常 2 关闭
            base: {
              name: "string",
              billingMode: [0],
              icon: "string",
              billingCycle: 0,
              cate: 0,
              description: "string",
              appStoreUrl: "string",
              googlePlayUrl: "string",
              H5Url: "string",
              openCpaSubsidy: true,
              CPASubsidies: [
                {
                  thresholdBegin: 0,
                  thresholdEnd: 0,
                  award: 0,
                },
              ],
            },
            vipDeduction: {
              opened: true,
              threshold: 0,
              count: 0,
            },
            goldDeduction: {
              opened: true,
              threshold: 0,
              count: 0,
            },
          },
        ],
      },
      addChannelFormRules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        remark: [{ required: false, message: "请输入备注", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        vipProp: [{ validator: vipRules, trigger: "blur" }],
        goldProp: [{ validator: coinRules, trigger: "blur" }],
      },
      // --------------------修改渠道基本信息form--------------------
      updateForm1: {
        ID: 0,
        remark: "",
        state: 0,
        billingMode: 1,
      },
      updateForm1Rules: {},
      // --------------------修改渠道产品信息form--------------------
      updateForm2: {},
      updateForm2Rules: {
        "app.vipProp": [{ validator: vipRules, trigger: "blur" }],
        "app.goldProp": [{ validator: coinRules, trigger: "blur" }],
      },
      // --------------------查看详情form--------------------
      channelDetailForm: {
        agent: {
          agent: {
            ID: 37,
            account: "t",
            billingMode: 1,
            businessUserID: 2,
            level: 1,
            state: 1,
          },
          app: [],
        },
        parentName: "",
      },
      channelInfoForm: {
        // email: "1231@qq.com",
        // QQ: "123123",
        // weixin: "weixin001",
        // whatsapp: "123131",
        // line: "line0000111",
        // telegram: "telegram0001",
        // payType: "支付宝",
        // payName: "张三",
        // payAccount: "622555565464646465",
        // remark: "备注12313123123123",
        // limit: 10,
      },
    };
  },
  mounted() {
    this.getAgent();
    this.getProductList();
  },
  methods: {
    // --------------------请求函数--------------------
    async getAgent() {
      if (this.queryInfo.timeRange === null) {
        this.queryInfo.timeRange = [
          new Date(new Date("1970-01-01 00:00:00")),
          new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
              3600 * 24 * 1000
          ),
        ];
      }
      let res = await _getAgent({
        page: this.page,
        pageSize: this.pageSize,
        ID: this.queryInfo.ID ? parseInt(this.queryInfo.ID) : 0,
        level: parseInt(this.queryInfo.level),
        state: parseInt(this.queryInfo.state),
        appID: parseInt(this.queryInfo.appID),
        createTimeBegin: this.queryInfo.timeRange[0],
        createTimeEnd: this.queryInfo.timeRange[1],
      });
      if (res && (res.status === 200 || res.status === 204)) {
        this.channelList = res.data.list;
        this.total = res.data.total;
        console.log(this.channelList);
      }
    },
    async postAdmin() {
      console.log(this.addChannelForm.app, "postAdmin");
      //

      let tempApps = [];
      for (const key in this.addChannelForm.app) {
        let tempObj = {
          ID: this.addChannelForm.app[key].ID,
          status: parseInt(this.addChannelForm.app[key].status),
          vipProp: isNaN(parseInt(this.addChannelForm.app[key].vipProp))
            ? 0
            : parseInt(this.addChannelForm.app[key].vipProp),
          goldProp: parseInt(this.addChannelForm.app[key].goldProp),
          vipDeduction: {
            opened: this.addChannelForm.app[key].switch1,
            threshold: parseInt(this.addChannelForm.app[key].vip),
            count: 1,
          },
          goldDeduction: {
            opened: this.addChannelForm.app[key].switch2,
            threshold: parseInt(this.addChannelForm.app[key].coin),
            count: 1,
          },
        };
        tempApps.push(tempObj);
      }
      console.log(tempApps, "apps");
      // --------------------addForm--------------------

      console.log();
      let res = await _postAgent({
        agent: {
          account: this.addChannelForm.account, //登录账号
          level: 1, //admin端创建默认为1级代理
          state: parseInt(this.addChannelForm.state), //代理状态 1 正常 2封禁 3 未激活
          remark: this.addChannelForm.remark,
          billingMode: 1,
        },
        pwd: this.addChannelForm.password,
        app: tempApps,
        // pwd: "123456",
      });

      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res);
        this.getAgent();
        this.addChannelDialogFormVisible = false;
      }
    },
    async getProductList() {
      let result = await _getProduct({});
      if (result && result.status === 200) {
        console.log(result.data, "productlist");
        result.data.list.map((item) => {
          item.vipProp = "";
          item.goldProp = "";
          item.status = "2";
          item.vip = "";
          item.switch1 = false;
          item.coin = "";
          item.switch2 = false;
          // item.status = 2; //开通状态 1：正常 2 关闭
        });
        this.productList = result.data.list;
      }
    },
    async getAgentByID(id) {
      let res = await _getAgentByID({
        ID: id,
      });
      if (res && res.status === 200) {
        this.channelDetailForm = res.data;
        console.log(this.channelDetailForm, "通过ID获取详情");
        this.updateForm1.ID = this.channelDetailForm.agent.agent.ID;
        this.updateForm1.remark = this.channelDetailForm.agent.agent.remark;
        this.updateForm1.state =
          this.channelDetailForm.agent.agent.state.toString();
        this.updateForm2.app = res.data.agent.app;
        this.updateForm2.ID = res.data.agent.agent.ID;

        for (const key in this.updateForm2.app) {
          this.updateForm2.app[key].status =
            this.updateForm2.app[key].status.toString();
          // console.log(this.updateForm2.app[key].base.ID,'ID');
          this.updateForm2.app[key].ID = this.updateForm2.app[key].base.ID;
          if (this.updateForm2.app[key].openTime) {
            // this.updateForm2.app[key].openTime = this.dateFormat(this.updateForm2.app[key].openTime,'YYYY-mm-dd')
            // this.updateForm2.app[key].openTime = ''
            delete this.updateForm2.app[key].openTime;
          }
          if (!this.updateForm2.app[key].goldProp) {
            this.$set(this.updateForm2.app[key], "goldProp", 0);
          }
          if (!this.updateForm2.app[key].vipProp) {
            this.$set(this.updateForm2.app[key], "vipProp", 0);
          }
          if (this.updateForm2.app[key].vipDeduction.threshold) {
            this.$set(
              this.updateForm2.app[key],
              "vip",
              this.updateForm2.app[key].vipDeduction.threshold
            );
          } else {
            this.$set(this.updateForm2.app[key], "vip", "");
          }
          if (this.updateForm2.app[key].vipDeduction.opened) {
            this.$set(this.updateForm2.app[key], "switch1", true);
          } else {
            this.$set(this.updateForm2.app[key], "switch1", false);
          }

          if (this.updateForm2.app[key].goldDeduction.threshold) {
            this.$set(
              this.updateForm2.app[key],
              "coin",
              this.updateForm2.app[key].goldDeduction.threshold
            );
          } else {
            this.$set(this.updateForm2.app[key], "coin", "");
          }
          if (this.updateForm2.app[key].goldDeduction.opened) {
            this.$set(this.updateForm2.app[key], "switch2", true);
          } else {
            this.$set(this.updateForm2.app[key], "switch2", false);
          }
        }
        // console.log(res.data.agent.app, "t");
        // this.updateForm2.app = res.data.agent.app;
        console.log(this.updateForm2, "修改产品");
      }
    },
    async putAgentByID() {
      let res = await _putAgentByID({
        ID: this.updateForm1.ID,
        remark: this.updateForm1.remark,
        state: parseInt(this.updateForm1.state),
        billingMode: 1,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res);
        this.getAgent();
        this.update1DialogFormVisible = false;
      }
    },
    async putAgentByIDApp() {
      for (const key in this.updateForm2.app) {
        // console.log(this.updateForm2.app[key].vipProp);
        // console.log(this.updateForm2.app[key].goldProp);
        // console.log(this.updateForm2.app[key].vip);
        // console.log(this.updateForm2.app[key].coin);
        this.updateForm2.app[key].status = parseInt(
          this.updateForm2.app[key].status
        );
        if (this.updateForm2.app[key].vipProp) {
          this.updateForm2.app[key].vipProp = parseInt(
            this.updateForm2.app[key].vipProp
          );
        }
        if (this.updateForm2.app[key].goldProp) {
          this.updateForm2.app[key].goldProp = parseInt(
            this.updateForm2.app[key].goldProp
          );
        }
        if (this.updateForm2.app[key].vip) {
          this.updateForm2.app[key].vip = parseInt(
            this.updateForm2.app[key].vip
          );
        }
        if (this.updateForm2.app[key].coin) {
          this.updateForm2.app[key].coin = parseInt(
            this.updateForm2.app[key].coin
          );
        }
        if (this.updateForm2.app[key].vip) {
          this.updateForm2.app[key].vipDeduction.threshold = parseInt(
            this.updateForm2.app[key].vip
          );
        }
        if (this.updateForm2.app[key].coin) {
          this.updateForm2.app[key].goldDeduction.threshold = parseInt(
            this.updateForm2.app[key].coin
          );
        }
        if (this.updateForm2.app[key].switch1) {
          this.updateForm2.app[key].vipDeduction.opened = true;
        }
        if (this.updateForm2.app[key].switch2) {
          this.updateForm2.app[key].goldDeduction.opened = true;
        }
      }
      let res = await _putAgentByIDApp({
        ID: this.updateForm2.ID,
        apps: this.updateForm2.app,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        this.getAgent();
        this.update2DialogFormVisible = false;
      }
    },
    // --------------------公共函数--------------------
    handleClose(done) {
      this.$messagebox
        .confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.page = 1;
      this.getAgent();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getAgent();
    },

    // --------------------处理函数--------------------

    handleClick(type, e) {
      // console.log(e,"打印参数");
      switch (type) {
        case "select":
          if (this.queryInfo.timeRange === null) {
            this.queryInfo.timeRange = [
              new Date(new Date("1970-01-01 00:00:00")),
              new Date(
                new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
                  3600 * 24 * 1000
              ),
            ];
          }
          console.log(this.queryInfo, "打印查询参数");
          this.getAgent();
          break;
        case "resetSelect":
          console.log("重置查询参数并请求");
          this.page = 1;
          this.pageSize = 10;
          this.queryInfo = {
            ID: "",
            appID: "0",
            level: "0",
            state: "0", // 1 正常 2封禁 3 未激活
            timeRange: [
              new Date(new Date("1970-01-01 00:00:00")),
              new Date(
                new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
                  3600 * 24 * 1000
              ),
            ],
          };
          this.getAgent();

          break;
        case "add":
          this.addChannelDialogFormVisible = true;
          this.addChannelForm.app = JSON.parse(
            JSON.stringify(this.productList)
          );
          break;
        case "channlDetail":
          console.log(e.agent.agent.ID);
          this.getAgentByID(e.agent.agent.ID);
          this.channelDetailDialogFormVisible = true;
          break;
        case "update1":
          // console.log(e.agent.agent.businessUserID);
          // console.log(this.uuid);
          if (e.agent.agent.businessUserID !== this.uuid) {
            this.$message.error("只能修改自己创建的渠道!");
            break;
          }
          console.log(e.agent.agent.ID, "修改渠道ID");
          this.getAgentByID(e.agent.agent.ID);
          this.update1DialogFormVisible = true;
          break;
        case "update2":
          if (e.agent.agent.businessUserID !== this.uuid) {
            this.$message.error("只能修改自己创建的渠道!");
            break;
          }
          console.log(e.agent.agent.ID, "修改渠道ID");
          this.getAgentByID(e.agent.agent.ID);
          this.update2DialogFormVisible = true;
          break;
        default:
          break;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addChannelForm":
              // console.log(this.addChannelForm);
              // console.log(this.productList);
              this.postAdmin();
              break;
            case "updateForm1":
              console.log(this.updateForm1, "修改渠道基本信息表单");
              this.putAgentByID();
              break;
            case "updateForm2":
              console.log(this.updateForm2, "修改渠道产品表单");
              this.putAgentByIDApp();
              break;

            default:
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    dateFormat(date, fmt) {
      // ----------临时处理----------0
      // 区分后端传的是毫秒还是秒
      // let str = date + ''
      // if(str.length <= "10"){
      //     date= date*1000
      // }
      // ----------临时处理----------1
      // if(isNaN(date)){
      //     return '1970-01-01 08:00:00'
      // }
      let ret = "";
      date = new Date(date);

      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
};
</script>