<template>
  <article>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 新增按钮 -->
    <el-button
      type="primary"
      @click="handleClick('add')"
      style="margin-bottom: 20px"
      >新增产品</el-button
    >
    <!--  -->
    <el-row :gutter="20">
      <el-col
        :sm="24"
        :lg="12"
        :xl="8"
        v-for="(item, index) in productList"
        :key="index"
      >
        <el-card shadow="hover">
          <div class="icon">
            <img :src="item.icon" alt="" srcset="" />
          </div>
          <div class="info">
            <el-row>
              <el-col :span="8"
                ><span>Key：{{ item.ID }}</span></el-col
              >
              <el-col :span="16"
                ><span>产品名称：{{ item.name }}</span></el-col
              >
              <el-col :span="8">
                <span v-if="item.openCpaSubsidy">分成模式：CPS+CPA</span>
                <span v-else>分成模式：CPS</span>
              </el-col>
              <el-col :span="16">
                <span v-if="item.billingCycle === 1">结算周期：日结</span>
                <span v-else-if="item.billingCycle === 2">结算周期：周结</span>
                <span v-else>结算周期：无</span>
              </el-col>
              <el-col :span="16"
                ><span
                  >开通时间：{{
                    item.createTime | dateFormat("YYYY-mm-dd HH:MM:SS")
                  }}</span
                ></el-col
              >
              <el-col :span="16"><span></span></el-col>
            </el-row>
          </div>
          <div class="desc">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="item.description"
              disabled
            >
            </el-input>
          </div>
          <div class="btn">
            <el-button
              type="primary"
              @click="handleClick('update', item)"
              size="mini"
              >编辑</el-button
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 新增dialog -->
    <el-dialog
      title="新增产品"
      :visible.sync="addDialogFormVisible"
      width="50%"
      :before-close="handleClose"
      @close="resetForm('addForm')"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="130px"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
        <el-form-item label="产品名称" prop="name">
          <el-input
            v-model="addForm.name"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品名称" prop="icon">
          <upload-img
            :img="addForm.icon"
            v-if="addDialogFormVisible"
            count="1"
            @dialog="dialog"
            @imgKey="aliImg1"
          ></upload-img>
          <!-- <upload-img-key :img="addForm.icon" @dialog="dialog" @outCropper="outCropper" @imgKey="imgKey">
                    </upload-img-key> -->
        </el-form-item>

        <el-form-item label="分类" prop="cate">
          <el-select v-model="addForm.cate" placeholder="请选择分类">
            <el-option label="直播类型" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算周期" prop="billingCycle">
          <el-select
            v-model="addForm.billingCycle"
            placeholder="请选择结算周期"
          >
            <el-option label="每日结算" value="1"></el-option>
            <el-option label="每周结算" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品介绍" prop="description">
          <el-input type="textarea" v-model="addForm.description"></el-input>
        </el-form-item>
        <el-form-item label="App Store链接" prop="appStoreUrl">
          <el-input v-model="addForm.appStoreUrl"></el-input>
        </el-form-item>
        <el-form-item label="google play链接" prop="googlePlayUrl">
          <el-input v-model="addForm.googlePlayUrl"></el-input>
        </el-form-item>
        <el-form-item label="H5链接" prop="H5Url">
          <el-input v-model="addForm.H5Url"></el-input>
        </el-form-item>
        <el-form-item label="" prop="desc">
          CPA补贴设置，默认关闭，请根据推广情况设置<br />
          <el-switch
            style="display: block"
            v-model="addForm.openCpaSubsidy"
            active-color="#13ce66"
            inactive-color="#ff4949"
            inactive-text="关闭"
            active-text="打开"
          >
          </el-switch>
        </el-form-item>
        <div v-if="addForm.openCpaSubsidy">
          <el-row :gutter="5">
            <el-col :span="8">
              <el-form-item label="" label-width="60px"
                >注册量下限</el-form-item
              >
            </el-col>
            <el-col :span="7">
              <el-form-item label="" label-width="20px"
                >注册量上限</el-form-item
              >
            </el-col>
            <el-col :span="6">
              <el-form-item label="" label-width="45px">奖励金额</el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="" label-width="0px">操作</el-form-item>
            </el-col>
          </el-row>

          <el-row
            :gutter="5"
            v-for="(item, index) in addForm.CPASubsidies"
            :key="item.key"
          >
            <el-col :span="8">
              <el-form-item
                :label="'阶段' + index"
                :prop="'CPASubsidies.' + index + '.thresholdBegin'"
                label-width="60px"
                :rules="{
                  required: true,
                  message: '下限不能为空',
                  trigger: 'blur',
                }"
              >
                <el-input v-model="item.thresholdBegin"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item
                label="~"
                :prop="'CPASubsidies.' + index + '.thresholdEnd'"
                label-width="20px"
                :rules="{
                  required: true,
                  message: '上限不能为空',
                  trigger: 'blur',
                }"
              >
                <el-input v-model="item.thresholdEnd"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="奖励"
                :prop="'CPASubsidies.' + index + '.award'"
                label-width="45px"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur',
                }"
              >
                <el-input v-model="item.award"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="" label-width="0px">
                <el-button @click.prevent="removeCPA('addForm', item)"
                  >删除</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-button @click="addCPA('addForm')">新增</el-button>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('addForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改dialog -->
    <el-dialog
      title="修改产品"
      :visible.sync="updateDialogFormVisible"
      width="50%"
      :before-close="handleClose"
      @close="resetForm('updateForm')"
    >
      <el-form
        :model="updateForm"
        :rules="updateFormRules"
        ref="updateForm"
        label-width="130px"
        :hide-required-asterisk="true"
        class="demo-ruleForm"
      >
        <el-form-item label="产品名称" prop="name">
          <el-input
            v-model="updateForm.name"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品图标" prop="icon">
          <upload-img
            :img="updateForm.icon"
            v-if="updateDialogFormVisible"
            count="1"
            @dialog="dialog"
            @imgKey="aliImg2"
          ></upload-img>
        </el-form-item>

        <el-form-item label="分类" prop="cate">
          <el-select v-model="updateForm.cate" placeholder="请选择分类">
            <el-option label="直播类型" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算周期" prop="billingCycle">
          <el-select
            v-model="updateForm.billingCycle"
            placeholder="请选择结算周期"
          >
            <el-option label="每日结算" value="1"></el-option>
            <el-option label="每周结算" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品介绍" prop="description">
          <el-input type="textarea" v-model="updateForm.description"></el-input>
        </el-form-item>
        <el-form-item label="App Store链接" prop="appStoreUrl">
          <el-input v-model="updateForm.appStoreUrl"></el-input>
        </el-form-item>
        <el-form-item label="google play链接" prop="googlePlayUrl">
          <el-input v-model="updateForm.googlePlayUrl"></el-input>
        </el-form-item>
        <el-form-item label="H5链接" prop="H5Url">
          <el-input v-model="updateForm.H5Url"></el-input>
        </el-form-item>
        <el-form-item label="" prop="desc">
          CPA补贴设置，默认关闭，请根据推广情况设置<br />
          <el-switch
            style="display: block"
            v-model="updateForm.openCpaSubsidy"
            active-color="#13ce66"
            inactive-color="#ff4949"
            inactive-text="关闭"
            active-text="打开"
          >
          </el-switch>
        </el-form-item>
        <div v-if="updateForm.openCpaSubsidy">
          <el-row :gutter="5">
            <el-col :span="8">
              <el-form-item label="" label-width="60px"
                >注册量下限</el-form-item
              >
            </el-col>
            <el-col :span="7">
              <el-form-item label="" label-width="20px"
                >注册量上限</el-form-item
              >
            </el-col>
            <el-col :span="6">
              <el-form-item label="" label-width="45px">奖励金额</el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="" label-width="0px">操作</el-form-item>
            </el-col>
          </el-row>

          <el-row
            :gutter="5"
            v-for="(item, index) in updateForm.CPASubsidies"
            :key="item.key"
          >
            <el-col :span="8">
              <el-form-item
                :label="'阶段' + index"
                :prop="'CPASubsidies.' + index + '.thresholdBegin'"
                label-width="60px"
                :rules="{
                  required: true,
                  message: '下限不能为空',
                  trigger: 'blur',
                }"
              >
                <el-input v-model="item.thresholdBegin"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item
                label="~"
                :prop="'CPASubsidies.' + index + '.thresholdEnd'"
                label-width="20px"
                :rules="{
                  required: true,
                  message: '上限不能为空',
                  trigger: 'blur',
                }"
              >
                <el-input v-model="item.thresholdEnd"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="奖励"
                :prop="'CPASubsidies.' + index + '.award'"
                label-width="45px"
              >
                <el-input v-model="item.award"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="" label-width="0px">
                <el-button @click.prevent="removeCPA('updateForm', item)"
                  >删除</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-button @click="addCPA('updateForm')">新增</el-button>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('updateForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 图片组件上传放大查看dialog -->
    <el-dialog :visible.sync="addImgPreviewDialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </article>
</template>

<script>
import UploadImg from "../../components/upload/UploadImg.vue";
import {
  _getProduct,
  _postProduct,
  _getProductByID,
  _putProductByID,
} from "@/api/index.js";
export default {
  name: "ProductManage",
  components: { UploadImg },

  data() {
    //
    var CPASubsidiesRules = (rule, value, callback) => {
      if (value.length < 1) {
        return callback(new Error("年龄不能为空"));
      } else {
        callback();
      }
    };
    return {
      queryInfo: {},
      // --------------------dialog--------------------
      addDialogFormVisible: false,
      updateDialogFormVisible: false,
      addImgPreviewDialogVisible: false,
      // --------------------form--------------------
      addForm: {
        name: "test-name",
        icon: [],
        cate: "1",
        billingCycle: "1",
        description: "描述",
        appStoreUrl: "appleLink",
        googlePlayUrl: "googleLink",
        H5Url: "H5Link",
        openCpaSubsidy: false,
        CPASubsidies: [{ thresholdBegin: "", thresholdEnd: "", award: "" }],
      },
      addFormRules: {
        CPASubsidies: [{ validator: CPASubsidiesRules, trigger: "change" }],
        name: [{ required: true, message: "请输入产品名称", trigger: "blur" }],
        icon: [
          { required: true, message: "请上传产品图片", trigger: "change" },
        ],
        cate: [{ required: true, message: "请选择分类", trigger: "change" }],
        billingCycle: [
          { required: true, message: "请选择结算周期", trigger: "change" },
        ],
        description: [
          { required: true, message: "请输入产品介绍", trigger: "blur" },
        ],
        appStoreUrl: [
          { required: false, message: "请输入App Store链接", trigger: "blur" },
        ],
        googlePlayUrl: [
          {
            required: false,
            message: "请输入google play链接",
            trigger: "blur",
          },
        ],
        H5Url: [{ required: false, message: "请输入H5链接", trigger: "blur" }],
      },
      updateForm: {
        name: "",
        icon: "",
        cate: "",
        billingCycle: "",
        description: "",
        appStoreUrl: "",
        googlePlayUrl: "",
        H5Url: "",
        openCpaSubsidy: false,
        CPASubsidies: [
          // { thresholdBegin: 1, thresholdEnd: 100, award: 0.5 },
        ],
      },
      updateFormRules: {
        name: [{ required: true, message: "请输入产品名称", trigger: "blur" }],

        "CPASubsidies.thresholdBegin": [
          { required: true, message: "请输入H5链接", trigger: "blur" },
        ],
        "CPASubsidies.thresholdEnd": [
          { required: true, message: "请输入H5链接", trigger: "blur" },
        ],
        "CPASubsidies.award": [
          { required: true, message: "请输入H5链接", trigger: "blur" },
        ],
      },

      productList: [],
      //
      dialogImageUrl: "",
    };
  },
  mounted() {
    this.getProductList();
  },
  methods: {
    // --------------------请求函数--------------------
    // 请求产品列表
    async getProductList() {
      let result = await _getProduct({});
      if (result && result.status === 200) {
        console.log(result.data);
        this.productList = result.data.list;
      }
    },
    // 创建新产品请求
    async postProduct() {
      // 如果不开启CPA相应的参数都不传
      if (this.addForm.openCpaSubsidy) {
        // this.addForm.CPASubsidies
        for (const key in this.addForm.CPASubsidies) {
          console.log(this.addForm.CPASubsidies[key]);
          this.addForm.CPASubsidies[key].award = parseFloat(
            this.addForm.CPASubsidies[key].award
          );
          this.addForm.CPASubsidies[key].thresholdEnd = parseInt(
            this.addForm.CPASubsidies[key].thresholdEnd
          );
          this.addForm.CPASubsidies[key].thresholdBegin = parseInt(
            this.addForm.CPASubsidies[key].thresholdBegin
          );
        }
        let res = await _postProduct({
          name: this.addForm.name,
          billingMode: [1], //这里写定为[1]
          icon: this.addForm.icon[0],
          billingCycle: parseInt(this.addForm.billingCycle),
          cate: parseInt(this.addForm.cate),
          description: this.addForm.description,
          appStoreUrl: this.addForm.appStoreUrl,
          googlePlayUrl: this.addForm.googlePlayUrl,
          H5Url: this.addForm.H5Url,
          openCpaSubsidy: this.addForm.openCpaSubsidy,
          CPASubsidies: this.addForm.CPASubsidies,
        });
        if (res && (res.status === 200 || res.status === 204)) {
          console.log(res);
          this.addDialogFormVisible = false;
          this.getProductList();
        }
      } else {
        let res = await _postProduct({
          name: this.addForm.name,
          billingMode: [1],
          icon: this.addForm.icon[0],
          billingCycle: parseInt(this.addForm.billingCycle),
          cate: parseInt(this.addForm.cate),
          description: this.addForm.description,
          appStoreUrl: this.addForm.appStoreUrl,
          googlePlayUrl: this.addForm.googlePlayUrl,
          H5Url: this.addForm.H5Url,
          openCpaSubsidy: this.addForm.openCpaSubsidy,
          CPASubsidies: [{}],
        });
        if (res && (res.status === 200 || res.status === 204)) {
          console.log(res);
          this.addDialogFormVisible = false;
          this.getProductList();
        }
      }
    },
    // 查看单个产品详情
    async getProductByID(ID) {
      let res = await _getProductByID({
        ID: ID,
      });
      if (res && res.status === 200) {
        // console.log(res.data, "查看单个产品详情");
        res.data.icon = [res.data.icon];
        this.updateForm = res.data;
        if (!this.updateForm.CPASubsidies) {
          this.updateForm.CPASubsidies = [];
        }
        this.updateForm.cate = this.updateForm.cate.toString();
        this.updateForm.billingCycle = this.updateForm.billingCycle.toString();
        console.log(this.updateForm, "查看单个产品详情");
        this.updateDialogFormVisible = true;
      }
    },
    async postProductByID() {
      if (this.updateForm.openCpaSubsidy) {
        for (const key in this.updateForm.CPASubsidies) {
          console.log(this.updateForm.CPASubsidies[key]);
          this.updateForm.CPASubsidies[key].award = parseFloat(
            this.updateForm.CPASubsidies[key].award
          );
          this.updateForm.CPASubsidies[key].thresholdEnd = parseInt(
            this.updateForm.CPASubsidies[key].thresholdEnd
          );
          this.updateForm.CPASubsidies[key].thresholdBegin = parseInt(
            this.updateForm.CPASubsidies[key].thresholdBegin
          );
        }
        let res = await _putProductByID({
          ID: this.updateForm.ID,
          name: this.updateForm.name,
          billingMode: [1], //这里写定为[1]
          icon: this.updateForm.icon[0],
          billingCycle: parseInt(this.updateForm.billingCycle),
          cate: parseInt(this.updateForm.cate),
          description: this.updateForm.description,
          appStoreUrl: this.updateForm.appStoreUrl,
          googlePlayUrl: this.updateForm.googlePlayUrl,
          H5Url: this.updateForm.H5Url,
          openCpaSubsidy: this.updateForm.openCpaSubsidy,
          CPASubsidies: this.updateForm.CPASubsidies,
        });
        if (res && (res.status === 200 || res.status === 204)) {
          console.log(res);
          this.updateDialogFormVisible = false;
          this.getProductList();
        }
      } else {
        let res = await _putProductByID({
          ID: this.updateForm.ID,
          name: this.updateForm.name,
          billingMode: [1],
          icon: this.updateForm.icon[0],
          billingCycle: parseInt(this.updateForm.billingCycle),
          cate: parseInt(this.updateForm.cate),
          description: this.updateForm.description,
          appStoreUrl: this.updateForm.appStoreUrl,
          googlePlayUrl: this.updateForm.googlePlayUrl,
          H5Url: this.updateForm.H5Url,
          openCpaSubsidy: this.updateForm.openCpaSubsidy,
        });
        if (res && (res.status === 200 || res.status === 204)) {
          console.log(res);
          this.updateDialogFormVisible = false;
          this.getProductList();
        }
      }
    },
    handleClick(type, e) {
      switch (type) {
        case "add":
          this.addDialogFormVisible = true;
          break;
        case "update":
          console.log(e.ID, "修改");
          this.getProductByID(e.ID);

          break;

        default:
          break;
      }
    },
    handleClose(done) {
      this.$messagebox
        .confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addForm":
              console.log(this.addForm);
              this.postProduct();
              break;
            case "updateForm":
              console.log(this.updateForm);
              this.postProductByID();
              break;

            default:
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeCPA(formName, item) {
      console.log(item);
      switch (formName) {
        case "addForm":
          console.log(this.addForm.CPASubsidies.length);
          if (this.addForm.CPASubsidies.length <= 1) {
            this.$message.error("至少保留一个阶段!");
          } else {
            var index = this.addForm.CPASubsidies.indexOf(item);
            if (index !== -1) {
              this.addForm.CPASubsidies.splice(index, 1);
            }
          }

          break;
        case "updateForm":
          if (this.updateForm.CPASubsidies.length <= 1) {
            this.$message.error("至少保留一个阶段!");
          } else {
            var index = this.updateForm.CPASubsidies.indexOf(item);
            if (index !== -1) {
              this.updateForm.CPASubsidies.splice(index, 1);
            }
          }
          break;

        default:
          break;
      }
    },
    addCPA(formName) {
      switch (formName) {
        case "addForm":
          this.addForm.CPASubsidies.push({
            thresholdBegin: "",
            thresholdEnd: "",
            award: "",
            key: Date.now(),
          });
          break;
        case "updateForm":
          console.log(this.updateForm);
          this.updateForm.CPASubsidies.push({
            thresholdBegin: "",
            thresholdEnd: "",
            award: "",
            key: Date.now(),
          });

          break;

        default:
          break;
      }
    },
    aliImg1(key) {
      //   console.log(key);
      this.addForm.icon = key; //静态封面
    },
    aliImg2(key) {
      //   console.log(key);
      this.updateForm.icon = key; //静态封面
    },
    dialog(e) {
      this.dialogImageUrl = e;
      this.addImgPreviewDialogVisible = true;
    },
  },
};
</script>

<style>
.el-card {
  min-width: 450px;
  margin: 0 10px 20px 0;
}

.icon {
  float: left;
  width: 80px;
  height: 80px;
  border-radius: 15px;
  overflow: hidden;
}

.icon img {
  width: 100%;
  height: 100%;
}

.info {
  float: left;
  height: 80px;
  margin-left: 20px;
  width: calc(100% - 100px);
}

.info span {
  display: inline-block;
  line-height: 26.66px;
}

.desc {
  margin: 100px 0 20px 0;
}
</style>