import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// axios拦截器 
import "./api/intercepors"
// element-ui 按需引入
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css';


// 全局过滤器
import "./filter/filter.js"
// Nprogress 样式引入
import 'nprogress/nprogress.css';




import "./assets/css/reset.css" //样式重置
import "./assets/css/common.css"  //全局样式

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
