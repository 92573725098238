import Vue from 'vue'
import {Container,Header,Aside,Main,Message,MessageBox,Button,Form,FormItem,Input,Menu,MenuItem,Submenu,Dropdown,DropdownMenu,DropdownItem,Image,DatePicker,Table,TableColumn,Tag,Pagination,Dialog,Select,Option,Row,Col,Upload,Popover,MenuItemGroup,Card,PageHeader,Breadcrumb,BreadcrumbItem,Collapse,CollapseItem, Tree, Switch,Checkbox,InputNumber,CheckboxGroup} from 'element-ui'

Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Image)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Popover)
Vue.use(MenuItemGroup)
Vue.use(Card)
Vue.use(PageHeader)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Tree)
Vue.use(Switch)
Vue.use(Checkbox)
Vue.use(InputNumber)
Vue.use(CheckboxGroup)

// Vue.prototype.$ELEMENT = { size: 'small'};
Vue.prototype.$message = Message
Vue.prototype.$messagebox = MessageBox


