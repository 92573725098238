import Vue from 'vue'

// 时间格式过滤器
Vue.filter('dateFormat', function (date, fmt) {
    // ----------临时处理----------0
    // 区分后端传的是毫秒还是秒
    // let str = date + ''
    // if(str.length <= "10"){
    //     date= date*1000
    // }
    // ----------临时处理----------1
    // if(isNaN(date)){
    //     return '1970-01-01 08:00:00'
    // }
    let ret = "";
    date = new Date(date);
    
    const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(
                ret[1],
                ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
            );
        }
    }
    return fmt;
})


