<template>
  <article>
    <div class="page-404">
      <i>404</i><br />
      <i>NOT FOUND</i>
      <el-button @click="backToLogin()" type="primary">Back To Login Page</el-button>
    </div>
  </article>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    backToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
article {
  position: relative;
}
.page-404 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: 350px;
  height: 350px;
  font-size: 2.25rem;
  text-align: center;
}
.el-button {
  font-weight: 700;
  font-size: 1rem;
  border-width: 2px;
}
.el-button:hover {
  color: #409eff;
  background-color: #fff;
}
</style>