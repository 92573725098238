<template>
  <div class="login"></div>
</template>

<script>
import { _getState, _postToken } from "../api/index.js";
export default {
  data() {
    return {
      state: "", //url拼接参数
      urlList: {
        code: "",
        scope: "",
        state: "",
      },
      refreshToken: localStorage.getItem("refreshToken"),
      // loginPageUrl: `http://192.168.2.134:4444/oauth2/auth?audience=&client_id=cps_client&max_age=0&nonce=fdsafdasfadsffdsafsdafsdaf&prompt=&redirect_uri=http://localhost:3001/login&response_type=code&scope=openid+offline&state=${this.state}`,
      // loginPageUrl: `http://hydrapubic.talku.vip/oauth2/auth?audience=&client_id=cps_client&max_age=0&nonce=fdsafdasfadsffdsafsdafsdaf&prompt=&redirect_uri=http://192.168.2.111:3001/login&response_type=code&scope=openid+offline&state=${this.state}`,
      // loginPageUrl: `https://hydrapubic.talku.vip/oauth2/auth?audience=&client_id=cps_client&max_age=0&nonce=fdsafdasfadsffdsafsdafsdaf&prompt=&redirect_uri=https://union.talku.vip/login&response_type=code&scope=openid+offline&state=${this.state}`,
      loginPageUrl: `https://hydrapubic.distributorshub.com/oauth2/auth?audience=&client_id=cps_client&max_age=0&nonce=fdsafdasfadsffdsafsdafsdaf&prompt=&redirect_uri=https://union.distributorshub.com/login&response_type=code&scope=openid+offline&state=${this.state}`,
    };
  },
  created() {},
  mounted() {
    this.getState();
  },
  methods: {
    // --------------------请求函数--------------------
    // 请求页面url的state参数
    async getState() {
      let res = await _getState({});
      if (res && res.status === 200) {
        console.log(res.data, "url的state参数");
        this.state = res.data;
        this.getParams();
      }
      console.log(res);
    },
    async postToken(e) {
      // 通过授权码code获取时传code和state，刷新token时参数为refresh_token
      if (e === 1) {
        let res = await _postToken({
          code: this.urlList.code,
          refreshToken: "",
          state: this.urlList.state,
        });
        if (res && res.status === 200) {
          console.log(res.data, "token1");
          localStorage.setItem("idToken", res.data.idToken);
          localStorage.setItem("expiresIn", res.data.expiresIn);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          this.$router.push("/dashboard");
        }
      } else if (e === 2) {
        let res = await _postToken({
          refreshToken: this.refreshToken,
        });
        if (res && res.status === 200) {
          console.log(res.data, "token2");
          // localStorage.setItem('idToken', res.data.idToken)
          // localStorage.setItem('expiresIn', res.data.expiresIn)
          // localStorage.setItem('refreshToken', res.data.refreshToken)
        }
      } else {
      }
    },
    // --------------------处理函数--------------------
    getParams() {
      let idToken = localStorage.getItem("idToken");
      let expiresIn = localStorage.getItem("expiresIn");
      let refreshToken = localStorage.getItem("refreshToken");

      //
      if (refreshToken) {
        let nowTime = new Date().getTime(); //获取当前时间戳
        let oldTime = new Date(expiresIn).getTime(); //转换登录过期时间戳
        console.log(nowTime, this.dateFormat(nowTime, "YYYY-mm-dd HH:MM:SS"));
        console.log(oldTime, this.dateFormat(oldTime, "YYYY-mm-dd HH:MM:SS"));
        if (oldTime > nowTime) {
          console.log("token有效可以直接访问接口");
          this.$router.push("/dashboard");
        } else {
          console.log("token无效，需要用refreshToken重新请求token");
          // this.postToken(2)
          console.log("需要重新手动登录");
          if (this.$route.fullPath === "/login") {
            window.location.href = this.loginPageUrl;
          } else {
            let url = this.$route.fullPath;
            let index = url.indexOf("?") + 1; //获取?位置 确定截取起点
            url = url.slice(index, url.length);
            url = url.split("&");
            console.log(url);
            console.log(url.length, "url的length");
            this.urlList.code = url[0].slice(
              url[0].indexOf("=") + 1,
              url[0].length
            );
            this.urlList.scope = url[1].slice(
              url[1].indexOf("=") + 1,
              url[1].length
            );
            if (this.urlList.scope.indexOf("%")) {
              console.log(decodeURIComponent(this.urlList.scope));
              this.urlList.scope = decodeURIComponent(this.urlList.scope);
            }
            this.urlList.state = url[2].slice(
              url[2].indexOf("=") + 1,
              url[2].length
            );
          }
          this.postToken(1);
        }
      } else {
        console.log("需要重新手动登录");
        if (this.$route.fullPath === "/login") {
          window.location.href = this.loginPageUrl;
        } else {
          let url = this.$route.fullPath;
          let index = url.indexOf("?") + 1; //获取?位置 确定截取起点
          url = url.slice(index, url.length);
          url = url.split("&");
          console.log(url);
          console.log(url.length, "url的length");

          if (url.length >= 3) {
            this.urlList.code = url[0].slice(
              url[0].indexOf("=") + 1,
              url[0].length
            );
            this.urlList.scope = url[1].slice(
              url[1].indexOf("=") + 1,
              url[1].length
            );
            if (this.urlList.scope.indexOf("%")) {
              console.log(decodeURIComponent(this.urlList.scope));
              this.urlList.scope = decodeURIComponent(this.urlList.scope);
            }
            this.urlList.state = url[2].slice(
              url[2].indexOf("=") + 1,
              url[2].length
            );
          } else {
            // this.$router.push('/login')
            window.location.href = this.loginPageUrl;
            // window.location.href = `http://192.168.2.134:4444/oauth2/auth?audience=&client_id=cps_client&max_age=0&nonce=fdsafdasfadsffdsafsdafsdaf&prompt=&redirect_uri=http://localhost:3001/login&response_type=code&scope=openid+offline&state=${this.state}`;
            // window.location.href = `https://hydrapubic.talku.vip/oauth2/auth?audience=&client_id=cps_client&max_age=0&nonce=fdsafdasfadsffdsafsdafsdaf&prompt=&redirect_uri=https://union.talku.vip/login&response_type=code&scope=openid+offline&state=${this.state}`;
          }
        }
        this.postToken(1);
      }
    },

    //
    dateFormat(date, fmt) {
      // ----------临时处理----------0
      // 区分后端传的是毫秒还是秒
      let str = date + "";
      if (str.length <= "10") {
        date = date * 1000;
      }
      // ----------临时处理----------1

      let ret = "";
      date = new Date(date);
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  background-image: url("../assets/imgs/login_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>