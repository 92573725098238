import axios from "axios";
import qs from 'qs'


/**
 * 封装axios
 * @param manageSite 请求地址
 * @param url 接口地址
 * @param dataSource 接口参数
 * */
 export const http = {
    get: (manageSite, url, dataSource) => {
      return axios.get(manageSite + url, { params: dataSource })
    },
    getdown: (manageSite, url, dataSource) => {
      return axios.get(manageSite + url, { timeout: 120000,responseType: 'blob', params: dataSource })
    },
    post: (manageSite, url, dataSource) => {
      return axios.post(manageSite + url, dataSource)
    },
    put: (manageSite, url, dataSource) => {
      return axios.put(manageSite + url, dataSource, dataSource)
    },
    del: (manageSite, url, dataSource) => {
      return axios.delete(manageSite + url, { data: qs.stringify(dataSource) })
    },
    delNum: (manageSite, url, dataSource) => {
      return axios.delete(manageSite + url,{data:dataSource} )
    },
    /**
     * 配合protoBuffer使用进行二进制传输
     * */
    arrGet: (manageSite, url, dataSource) => axios.get(manageSite + url, { params: dataSource, responseType: 'arraybuffer' }),
    arrayPost: (manageSite, url, dataSource) => axios.post(manageSite + url, dataSource, { responseType: 'arraybuffer' })
  }