<template>
  <article>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>扣量管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询参数 -->
    <el-form :inline="true" :model="queryInfo" size="small">
      <!-- <el-form-item label="渠道ID：">
                <el-input v-model="queryInfo.ID"></el-input>
            </el-form-item> -->
      <el-form-item label="订单类型：">
        <el-select v-model="queryInfo.deduct" style="width: 100px">
          <el-option label="全部订单" value="0"></el-option>
          <el-option label="正常订单" value="1"></el-option>
          <el-option label="扣量订单" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品名称：">
        <el-select v-model="queryInfo.appID" style="width: 100px">
          <!-- TODO:选项抽离 -->
          <el-option
            v-for="item in appOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="条件筛选：">
                <el-select v-model="queryInfo.type">
                    <el-option label="充值金额-升序" value="1"></el-option>
                    <el-option label="充值金额-降序" value="2"></el-option>
                </el-select>
            </el-form-item> -->
      <el-form-item label="时间选择：">
        <el-date-picker
          v-model="queryInfo.timeRange"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleClick('select')"
          >查 询</el-button
        >
        <el-button type="primary" @click="handleClick('resetSelect')"
          >重 置</el-button
        >
      </el-form-item>
      <!-- 统计模块 -->
      <!-- <div>
        <el-form-item>
          <el-tag type="success"
            >充值订单量:{{ myData[0].diamonds + myData[0].vip }}笔</el-tag
          ><br />
          <el-tag type="success">钻石订单:{{ myData[0].diamonds }}</el-tag
          ><br />
          <el-tag type="success">vip订单:{{ myData[0].vip }}</el-tag
          ><br />
        </el-form-item>
        <el-form-item>
          <el-tag>订单金额$:{{ myData[1].diamonds + myData[1].vip }}</el-tag
          ><br />
          <el-tag>钻石订单:{{ myData[1].diamonds }}</el-tag
          ><br />
          <el-tag>vip订单:{{ myData[1].vip }}</el-tag
          ><br />
        </el-form-item>
        <el-form-item>
          <el-tag type="info"
            >充值订单量:{{ myData[2].diamonds + myData[2].vip }}笔</el-tag
          ><br />
          <el-tag type="info">钻石订单:{{ myData[2].diamonds }}</el-tag
          ><br />
          <el-tag type="info">vip订单:{{ myData[2].vip }}</el-tag
          ><br />
        </el-form-item>
        <el-form-item>
          <el-tag type="danger"
            >已扣量订单:{{ myData[3].diamonds + myData[3].vip }}笔</el-tag
          ><br />
          <el-tag type="danger">钻石订单:{{ myData[3].diamonds }}</el-tag
          ><br />
          <el-tag type="danger">vip订单:{{ myData[3].vip }}</el-tag
          ><br />
        </el-form-item>
        <el-form-item>
          <el-tag type="warning"
            >充值订单量:{{ myData[4].diamonds + myData[4].vip }}笔</el-tag
          ><br />
          <el-tag type="warning">钻石订单:{{ myData[4].diamonds }}</el-tag
          ><br />
          <el-tag type="warning">vip订单:{{ myData[4].vip }}</el-tag
          ><br />
        </el-form-item>
      </div> -->
    </el-form>

    <!-- 表格 -->
    <el-table :data="deductionList" border>
      <el-table-column prop="ID" label="ID"></el-table-column>
      <el-table-column prop="agentID" label="代理ID"></el-table-column>
      <el-table-column prop="appName" label="产品名称"></el-table-column>
      <el-table-column prop="cate" label="充值类型">
        <template slot-scope="scope">
          <span v-if="scope.row.cate === 1">金币</span>
          <span v-if="scope.row.cate === 2">Vip</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="userID"
        label="充值用户ID"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="userRegisterTime"
        label="充值用户注册时间"
        width="165"
      >
        <template slot-scope="scope">
          {{ scope.row.userRegisterTime | dateFormat("YYYY-mm-dd HH:MM:SS") }}
        </template>
      </el-table-column>

      <el-table-column prop="amount" label="金额"></el-table-column>
      <el-table-column
        prop="orderID"
        label="订单ID"
        width="170"
      ></el-table-column>

      <el-table-column prop="rechargeTime" label="充值时间" width="165">
        <template slot-scope="scope">
          {{ scope.row.rechargeTime | dateFormat("YYYY-mm-dd HH:MM:SS") }}
        </template>
      </el-table-column>
      <el-table-column prop="deduct" label="是否扣量">
        <template slot-scope="scope">
          <span v-if="scope.row.deduct === 1">未扣量</span>
          <span v-if="scope.row.deduct === 2" style="color: red">扣量</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240">
        <template slot-scope="scope">
          <el-button
            type="danger"
            v-if="scope.row.deduct === 2"
            @click="handleClick('update', scope.row)"
            >将扣量订单修改为正常订单</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryInfo.total"
    >
    </el-pagination>
    <el-dialog
      title="修改扣量订单状态"
      :visible.sync="updateDialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <span>确定要将此扣量订单修改为正常订单吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleClick('update-confirm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </article>
</template>

<script>
import { _getDeduction, _putDeduction,_getProduct } from "../../api/index.js";
export default {
  data() {
    return {
      queryInfo: {
        appID: "0",
        deduct: "0", // 1不是扣量单（正常单）  2扣量单
        timeRange: null,
        page: 1,
        pageSize: 10,
        total: 0,
      },
      //
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      myData: [
        { diamonds: 800, vip: 100 },
        { diamonds: 1000, vip: 500 },
        { diamonds: 40, vip: 10 },
        { diamonds: 400, vip: 100 },
        { diamonds: 80, vip: 70 },
      ],
      deductionList: [
        {
          index: 1,
          product: "bat",
          payTime: "2022-05-05 10:25:05",
          userId: "1001",
        },
      ],
      updateDialogVisible: false,
      updateForm: {},
      //
      productList: [],
      appOptions: [{label:"全部",value:'0'}],
    };
  },
  mounted() {
    this.getProductList()
    this.getDeduction();
  },
  methods: {
    async getDeduction() {
      let res = await _getDeduction({
        appID: parseInt(this.queryInfo.appID),
        deduct: parseInt(this.queryInfo.deduct),
        page: this.queryInfo.page,
        pageSize: this.queryInfo.pageSize,
        startTime: new Date("1970-01-01 08:00:00"),
        endTime: new Date(),
      });
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res.data.list);
        this.queryInfo.total = res.data.total;
        this.deductionList = res.data.list;
      }
    },
    async updateDeduction() {
      let res = await _putDeduction({
        ID: this.updateForm.ID,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        this.getDeduction();
        console.log(res);
      }
    },
    async getProductList() {
      let result = await _getProduct({});
      if (result && result.status === 200) {
        this.productList = result.data.list;
        console.log(this.productList);
        for (const key in this.productList) {
          let obj = {
            value: this.productList[key].ID,
            label: this.productList[key].name,
          };
          this.appOptions.push(obj);
        }
        console.log(this.appOptions, "options");
      }
    },
    //
    handleSizeChange(val) {
      this.queryInfo.page = 1;
      this.queryInfo.pageSize = val;
      console.log(`每页 ${val} 条`);
      this.getDeduction();
    },
    handleCurrentChange(val) {
      this.queryInfo.page = val;
      console.log(`当前页: ${val}`);
      this.getDeduction();
    },
    handleClick(type, e) {
      switch (type) {
        case "select":
          console.log(e, "查询");
          this.queryInfo.page = 1;
          this.getDeduction();
          break;
        case "resetSelect":
          (this.queryInfo = {
            appID: "0",
            deduct: "0", // 1不是扣量单（正常单）  2扣量单
            timeRange: null,
            page: 1,
            pageSize: 10,
            total: 0,
          }),
            this.getDeduction();

          console.log(e, "重置");
          break;
        case "update":
          this.updateForm = JSON.parse(JSON.stringify(e));
          console.log(this.updateForm, "修改订单状态");
          this.updateDialogVisible = true;
          break;
        case "update-confirm":
          this.updateDeduction();
          this.updateDialogVisible = false;
          break;

        default:
          break;
      }
    },

    handleClose(done) {
      this.$messagebox
        .confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
