<template>
  <article>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>分享页</el-breadcrumb-item>
    </el-breadcrumb>
    <el-button
      @click="handleClick('add')"
      type="primary"
      style="margin-bottom: 20px"
      >新增海报</el-button
    >
    <el-form :inline="true" :model="queryInfo" size="small">
      <el-form-item label="产品类型：">
        <el-select v-model="queryInfo.appID">
          <el-option
            v-for="item in appOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推广环境：">
        <el-select v-model="queryInfo.envID" filterable>
          <el-option
            v-for="item in envOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleClick('select')"
          >查 询</el-button
        >
        <el-button type="primary" @click="handleClick('resetSelect')"
          >重 置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table :data="pageList" style="width: 100%" border>
      <el-table-column prop="ID" label="ID"></el-table-column>
      <el-table-column prop="appID" label="appID"></el-table-column>
      <el-table-column prop="appID" label="appName">
        <template slot-scope="scope">
          {{ appIDToAppName(scope.row.appID) }}
        </template>
      </el-table-column>
      <el-table-column prop="url" label="url"></el-table-column>
      <el-table-column prop="title" label="title"></el-table-column>
      <el-table-column label="envID">
        <template slot-scope="scope">
          {{ scope.row.env.ID }}
        </template>
      </el-table-column>
      <el-table-column label="envName">
        <template slot-scope="scope">
          {{ scope.row.env.name }}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="170">
        <template slot-scope="scope">
          {{ scope.row.createTime | dateFormat("YYYY-mm-dd HH:MM:SS") }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="175">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleClick('posters', scope.row)"
            >海报</el-button
          >
          <el-button type="warning" @click="handleClick('update', scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 新增 -->
    <el-dialog
      title="新增海报"
      :visible.sync="addDialogFormVisible"
      :before-close="handleClose"
      @closed="closeDialog('addForm')"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="链接名称" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="链接地址" prop="url">
          <el-input v-model="addForm.url"></el-input>
        </el-form-item>
        <el-form-item label="推广环境" prop="envID">
          <el-select v-model="addForm.envID" filterable>
            <el-option
              v-for="item in envOptions1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择应用" prop="appID">
          <el-select v-model="addForm.appID">
            <el-option
              v-for="item in appOptions1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上传海报背景" prop="posters">
          <upload-img
            :img="addForm.posters"
            v-if="addDialogFormVisible"
            count="9"
            @dialog="dialog"
            @imgKey="aliImg1"
          ></upload-img>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('addForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      title="修改海报"
      :visible.sync="updateDialogFormVisible"
      :before-close="handleClose"
      @closed="closeDialog('updateForm')"
    >
      <el-form
        :model="updateForm"
        :rules="updateFormRules"
        ref="updateForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="链接名称" prop="title">
          <el-input v-model="updateForm.title"></el-input>
        </el-form-item>
        <el-form-item label="链接地址" prop="url">
          <el-input v-model="updateForm.url"></el-input>
        </el-form-item>
        <el-form-item label="推广环境" prop="envID">
          <el-select v-model="updateForm.envID" filterable>
            <el-option
              v-for="item in envOptions1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上传海报背景" prop="posters">
          <upload-img
            :img="updateForm.posters"
            v-if="updateDialogFormVisible"
            count="9"
            @dialog="dialog"
            @imgKey="aliImg2"
          ></upload-img>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('updateForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看海报 -->
    <el-dialog
      title="提示"
      :visible.sync="posterDialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <!-- <canvas id="canvas"></canvas> -->
      <img
        v-for="(item, index) in this.postersList"
        :key="index"
        :src="item"
        alt=""
        style="width: 75px; float: left; margin: 0 25px 25px 0"
      />
      <img id="myImg" src="" alt="" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="posterDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="posterDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 图片组件上传放大查看dialog -->
    <el-dialog :visible.sync="addImgPreviewDialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </article>
</template>

<script>
import {
  _getShareEnv,
  _getSharePage,
  _postSharePage,
  _putSharePage,
  _getProduct,
} from "../../api/index.js";
import UploadImg from "../../components/upload/UploadImg.vue";
import QRCode from "qrcode";
export default {
  name: "SharePage",
  components: { UploadImg },
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      queryInfo: {
        appID: "0",
        envID: "0",
      },
      productList: [],
      appOptions: [{ value: "0", label: "全部" }], //appID的options
      appOptions1: [], //appID的options
      envList: [], //请求的分享环境列表
      envOptions: [], //分享环境列表处理为对应的options
      envOptions1: [], //分享环境列表处理为对应的options
      pageList: [], //分享页列表
      postersList: [], //查看海报临时列表

      //--------------------dialog--------------------
      posterDialogVisible: false,
      addDialogFormVisible: false,
      updateDialogFormVisible: false,

      addImgPreviewDialogVisible: false,
      //
      addForm: {
        title: "", //链接名称
        appID: "",
        posters: [],
        url: "",
        envID: "",
      },
      addFormRules: {
        title: [{ required: true, message: "请输入链接名称", trigger: "blur" }],
        url: [{ required: true, message: "请输入链接地址", trigger: "blur" }],
        appID: [{ required: true, message: "请选择应用", trigger: "change" }],
        envID: [
          { required: true, message: "请选择推广环境", trigger: "change" },
        ],
        posters: [
          { required: true, message: "请上传海报背景", trigger: "change" },
        ],
      },
      updateForm: {
        ID: "",
        title: "", //链接名称
        appID: "",
        posters: [],
        url: "",
        envID: "1",
      },
      updateFormRules: {
        title: [{ required: true, message: "请输入链接名称", trigger: "blur" }],
        url: [{ required: true, message: "请输入链接地址", trigger: "blur" }],
        appID: [{ required: true, message: "请选择应用", trigger: "change" }],
        envID: [
          { required: true, message: "请选择推广环境", trigger: "change" },
        ],
        posters: [
          { required: true, message: "请上传海报背景", trigger: "change" },
        ],
      },
      //
      dialogImageUrl: "",
    };
  },
  mounted() {
    this.getProductList();
    this.getShareEnv();
    this.getSharePage();
  },
  methods: {
    // --------------------请求函数--------------------
    // 请求分享环境并生成选项options
    async getShareEnv() {
      let res = await _getShareEnv({});
      if (res && (res.status === 200 || res.status === 204)) {
        // console.log(res.data.list);
        this.envList = res.data.list;
        // console.log(this.envList, '分享环境');
        let arr = [{ label: "全部", value: "0" }];
        let arr1 = [];
        for (const iterator of this.envList) {
          let t = {
            label: iterator.name + "(" + iterator.Cate.name + ")",
            value: iterator.ID + "",
          };
          arr.push(t);
          arr1.push(t);
        }
        this.envOptions = arr;
        this.envOptions1 = arr1;
        // console.log(this.envOptions)
      }
    },
    // 请求分享页列表
    async getSharePage() {
      let _this = this;
      let res = await _getSharePage({
        page: this.page,
        pageSize: this.pageSize,
        appID: parseInt(this.queryInfo.appID),
        envID: parseInt(this.queryInfo.envID),
      });
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res.data.list, "分享页列表");
        this.pageList = res.data.list;
        console.log(this.appOptions);
        // this.pageList.map((item) => {
        //   Object.assign(item, {
        //     appName: _this.appIDToAppName(item.appID),
        //   });
        // });
        this.total = res.data.total;
      }
    },
    async postSharePage() {
      let res = await _postSharePage({
        appID: parseInt(this.addForm.appID),
        title: this.addForm.title,
        url: this.addForm.url,
        env: {
          ID: parseInt(this.addForm.envID),
        },
        posters: this.addForm.posters,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res);
        this.getSharePage();
        this.addDialogFormVisible = false;
      }
    },
    async putSharePage() {
      let res = await _putSharePage({
        ID: this.updateForm.ID,
        appID: parseInt(this.updateForm.appID),
        title: this.updateForm.title,
        url: this.updateForm.url,
        env: {
          ID: parseInt(this.updateForm.envID),
        },
        posters: this.updateForm.posters,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res);
        this.getSharePage();
        this.updateDialogFormVisible = false;
      }
    },
    async getProductList() {
      let result = await _getProduct({});
      if (result && result.status === 200) {
        this.productList = result.data.list;
        // console.log(this.productList);
        for (const key in this.productList) {
          let obj = {
            value: this.productList[key].ID,
            label: this.productList[key].name,
          };
          this.appOptions.push(obj);
          this.appOptions1.push(obj);
        }
        // console.log(this.appOptions, "appOptions");
        // console.log(this.appOptions1, "appOptions1");
      }
    },
    // --------------------请求函数--------------------
    // 海报和图片合并
    async drawAndShareImage(imgSrc1, imgSrc2, url) {
      let t = await QRCode.toDataURL(url);
      console.log(t, "t-url");
      //imgSrc1 背景图片（二维码）链接
      //imgSrc2 小图片（头像）链接
      var canvas = document.createElement("canvas");
      canvas.width = 750;
      canvas.height = 1334;
      var context = canvas.getContext("2d");

      context.rect(0, 0, canvas.width, canvas.height);
      context.fillStyle = "#fff";
      context.fill();

      var myImage = new Image();
      myImage.src = imgSrc1 + `?t=${new Date().getTime()}`; //背景图片  你自己本地的图片或者在线图片
      myImage.crossOrigin = "Anonymous";

      myImage.onload = () => {
        context.drawImage(myImage, 0, 0, 750, 1334);
        var myImage2 = new Image();
        // myImage2.src = imgSrc2; //你自己本地的图片或者在线图片
        myImage2.src = t; //你自己本地的图片或者在线图片
        myImage2.crossOrigin = "Anonymous";

        myImage2.onload = () => {
          // 设置填充的颜色
          context.fillStyle = "white";
          // 绘制填充的矩形
          context.fillRect(550, 1134, 200, 200);
          //   context.drawImage(myImage2, 260, 260, 190, 190);
          context.drawImage(myImage2, 550, 1134, 200, 200);
          var base64 = canvas.toDataURL("image/png"); //"image/png" 这里注意一下
          // 获取图片容器
          var myImg = document.getElementById("myImg");
          myImg.src = base64;
          //下载海报
          //   var dlLink = document.createElement("a");
          //   dlLink.download = "poster";
          //   dlLink.href = myImg.src;
          //   dlLink.dataset.downloadurl = [
          //     "image/png",
          //     dlLink.download,
          //     dlLink.href,
          //   ].join(":");
          //   document.body.appendChild(dlLink);
          //   dlLink.click();
          //   document.body.removeChild(dlLink);
        };
      };
    },

    //
    handleClick(type, e) {
      switch (type) {
        case "add":
          this.addDialogFormVisible = true;
          break;
        case "posters":
          console.log(e, "海报");
          this.posterDialogVisible = true;
          this.postersList = e.posters;
          this.drawAndShareImage(e.posters[0], e.posters[1], e.url);
          break;

        case "update":
          console.log(e);
          this.updateForm.ID = e.ID;
          this.updateForm.title = e.title + "";
          this.updateForm.appID = e.appID + "";
          this.updateForm.envID = e.env.ID + "";
          this.updateForm.url = e.url;
          this.updateForm.posters = JSON.parse(JSON.stringify(e.posters));
          this.updateDialogFormVisible = true;
          break;

        default:
          break;
      }
    },
    //
    dialog(e) {
      this.dialogImageUrl = e;
      this.addImgPreviewDialogVisible = true;
    },
    aliImg1(key) {
      //   console.log(key);
      this.addForm.posters = key; //静态封面
    },
    aliImg2(key) {
      //   console.log(key);
      this.updateForm.posters = key; //静态封面
    },
    // 账户列表查询
    handleClose(done) {
      this.$messagebox
        .confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addForm":
              console.log(this.addForm);
              this.postSharePage();
              break;
            case "updateForm":
              console.log(this.updateForm);
              this.putSharePage();
              break;

            default:
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    closeDialog(formName) {
      this.$refs[formName].resetFields();
    },
    //
    handleSizeChange(val) {
      this.page = 1;
      this.pageSize = val;
      console.log(`每页 ${val} 条`);
      this.getSharePage();
    },
    handleCurrentChange(val) {
      this.page = val;
      console.log(`当前页: ${val}`);
      this.getSharePage();
    },
    // appID转appName
    appIDToAppName(appID) {
      let appName = "";
      for (let key in this.appOptions) {
        // console.log(this.appOptions[key].value);
        if (appID == this.appOptions[key].value) {
          appName = this.appOptions[key].label
        }
      }
      // console.log(appName);
      return appName
    },
  },
};
</script>

<style scope>
</style>

