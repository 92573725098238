<template>
  <article>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- ----------------------------------------查询---------------------------------------- -->

    <el-button
      @click="handleClick('newAccount')"
      type="primary"
      size="mini"
      style="margin-bottom: 10px"
    >
      创建角色
    </el-button>

    <el-table :data="rolesList" border stripe style="width: 100%">
      <el-table-column prop="ID" label="ID"></el-table-column>
      <el-table-column prop="name" label="角色名称"></el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 'system'">system</span>
          <span v-if="scope.row.type === 'custom'">custom</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="185">
        <template slot-scope="scope">
          <!-- <el-button @click="handleClick('detail', scope.row)" type="text" size="small">查看</el-button> -->
          <!-- <el-button
            @click="handleClick('update', scope.row)"
            type="warning"
            size="small"
            v-if="scope.row.type !== 'system'"
            >编辑</el-button
          > -->
          <el-button
            @click="handleClick('delete', scope.row)"
            type="danger"
            size="small"
            v-if="scope.row.type !== 'system'"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="pagination.page"
      :page-size="pagination.pageSize"
      layout="total,  prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <!-- 新建账户 -->
    <el-dialog
      title="新建角色"
      :visible.sync="addDialogFormVisible"
      :before-close="handleClose"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="ID：" label-width="100px" prop="ID">
          <el-input v-model="addForm.ID" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色名称：" label-width="100px" prop="name">
          <el-input v-model="addForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="" label-width="100px">
          <el-checkbox v-model="addForm.select"
            >创建用户时默认选中该角色</el-checkbox
          >
        </el-form-item>
        <el-form-item label="描述" label-width="100px" prop="description">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="addForm.description"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="角色权限：" label-width="100px">
          <div v-for="(item, index) in this.addForm.auth" :key="index">
            <el-checkbox
              :indeterminate="item.isIndeterminate"
              v-model="item.checkAll"
              @change="handleCheckAllChange(!item.checkAll, index)"
              >{{ item.alias }}
            </el-checkbox>
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="item.checkedOptions"
              @change="handleCheckedCitiesChange(item.checkedOptions, index)"
            >
              <el-checkbox
                v-for="i in item.options"
                :label="i.alias"
                :key="i.action"
                >{{ i.alias }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('addForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      title="修改角色"
      :visible.sync="updateDialogFormVisible"
      :before-close="handleClose"
    >
      <el-form
        :model="updateForm"
        :rules="updateFormRules"
        ref="updateForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="ID：" label-width="100px" prop="ID">
          <el-input v-model="updateForm.ID" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色名称：" label-width="100px" prop="name">
          <el-input v-model="updateForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="" label-width="100px">
          <el-checkbox v-model="updateForm.select"
            >创建用户时默认选中该角色</el-checkbox
          >
        </el-form-item>
        <el-form-item label="描述" label-width="100px" prop="description">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="updateForm.description"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="角色权限：" label-width="100px">
          <div v-for="(item, index) in this.updateForm.auth" :key="index">
            <el-checkbox
              :indeterminate="item.isIndeterminate"
              v-model="item.checkAll"
              @change="handleCheckAllChange1(!item.checkAll, index)"
              >{{ item.alias }}
            </el-checkbox>
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="item.checkedOptions"
              @change="handleCheckedCitiesChange1(item.checkedOptions, index)"
            >
              <el-checkbox
                v-for="i in item.options"
                :label="i.alias"
                :key="i.action"
                >{{ i.alias }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('updateForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </article>
</template>

<script>
import { _getPolicy, _getRole, _postRole, _delRole } from "../../api/index.js";
import { objectKey2Value } from "../../utils/common.js";
export default {
  name: "Account",
  data() {
    return {
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      queryInfo: {
        id: "",
        // ids: [0],
        // name: "string",
        // queryValue: "string",
        // status: 0,
        // userId: 0,
      },
      rolesList: [],
      policyList: [],
      trunList: {},
      // ----------dialog----------
      addDialogFormVisible: false,
      updateDialogFormVisible: false,
      addForm: {
        name: "",
        auth: [],
        select: false,
        description: "",
      },
      addFormRules: {
        ID: [
          { required: true, message: "请输入唯一的英文ID", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        description: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
      },
      updateForm: {
        name: "",
        auth: [],
        select: false,
        description: "",
      },
      updateFormRules: {
        ID: [
          { required: true, message: "请输入唯一的英文ID", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        description: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
      },
      //
      // checkAll: true,//全选状态
      // isIndeterminate: false,//不全选状态
      // checkedCities: ['上海', '北京'],//当前选项
      // cities: ['上海', '北京', '广州', '深圳'],//全选选项
    };
  },
  mounted() {
    this.getPolicy();
    this.getRole();
  },
  methods: {
    // --------------------请求函数--------------------
    //
    async getPolicy() {
      let res = await _getPolicy({});
      if (res && res.status === 200) {
        // console.log(res.data.list);
        let turnObj = {};
        for (const key in res.data.list) {
          res.data.list[key].options = [];
          res.data.list[key].checkedOptions = [];
          res.data.list[key].checkAll = false; //全选状态
          res.data.list[key].isIndeterminate = false; //不全选状态

          for (const iterator1 of res.data.list[key].rules) {
            res.data.list[key].options.push(iterator1);

            let key1 = iterator1.alias;
            let value1 = iterator1.action;
            turnObj[key1] = value1;
            turnObj[value1] = key1;
          }
        }
        console.log(turnObj, "fliter");
        this.trunList= turnObj

        this.policyList = res.data.list;
        // console.log(this.policyList, '请求权限列表');
        this.addForm.auth = this.policyList;
        this.updateForm.auth = this.policyList;
      }
    },
    async getRole() {
      let res = await _getRole({});
      if (res && res.status === 200) {
        // console.log(res.data.roles);
        this.rolesList = res.data.roles;
        console.log(this.rolesList, "查看角色列表");
      }
    },
    //
    async postRole() {
      let arr = [];
      this.addForm.auth.map((item) => {
        // console.log(item);
        if (item.checkedOptions.length > 0) {
          let t = {
            resources: [item.name],
            verbs: [],
          };
          for (const item1 of item.checkedOptions) {
            console.log(item1);
            for (const i of item.options) {
              if (item1 === i.alias) {
                t.verbs.push(i.action);
              }
            }
          }
          arr.push(t);
        }
      });
      console.log(arr);
      let res = await _postRole({
        ID: this.addForm.ID,
        name: this.addForm.name,
        rules: arr,
        select: this.addForm.select,
        description: this.addForm.description,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        this.getRole();
        this.addDialogFormVisible = false;
      }
    },
    async delRole(id) {
      let res = await _delRole({
        ID: id,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        this.getRole();
      }
    },
    // --------------------函数--------------------
    // 点击按钮
    // type  按钮类型 按钮传值
    handleClick(type, e) {
      switch (type) {
        case "newAccount":
          this.addDialogFormVisible = true;
          break;
        case "detail":
          console.log(e, "查看");
          break;
        case "update":
          // console.log(e, "修改");
          this.updateDialogFormVisible = true;
          this.updateForm.ID = e.ID;
          this.updateForm.name = e.name;
          this.updateForm.description = "";
          if (e.description) {
            this.updateForm.description = e.description;
          }
          this.updateForm.select = false;
          if (e.select) {
            this.updateForm.select = e.select;
          }
          this.updateForm.rules = JSON.parse(JSON.stringify(e.rules));
          for (const iterator of this.updateForm.auth) {
            // console.log(iterator.name);
            for (const iterator1 of this.updateForm.rules) {
              // console.log(iterator1);
              if(iterator1.resources[0] === iterator.name){
                console.log(iterator1.verbs);
                // let a = objectKey2Value(...iterator1.verbs,this.trunList)
                // console.log(a);
                // iterator.checkedOptions.push(a)
              }
            }
          }

          console.log(this.updateForm);

          break;
        case "delete":
          console.log(e.ID, "删除");
          this.$messagebox
            .confirm("确认要删除该角色吗?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              this.delRole(e.ID);
            });

          break;

        default:
          break;
      }
    },
    // --------------------公共函数--------------------
    // 关闭dialog清除数据
    closeDialog(formName) {
      this.$refs[formName].resetFields();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.page = val;
      this.getRole();
    },

    handleClose(done) {
      this.$messagebox
        .confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //
    handleCheckAllChange(val, index) {
      if (this.addForm.auth[index].checkAll) {
        for (const item of this.addForm.auth[index].options) {
          this.addForm.auth[index].checkedOptions.push(item.alias);
        }
      } else {
        this.addForm.auth[index].checkedOptions = []; //取消全选，清空checkbox-group的数组
      }

      console.log(this.addForm.auth[index].checkedOptions);
      this.addForm.auth[index].isIndeterminate = false;
    },
    handleCheckedCitiesChange(value, index) {
      // console.log(value, index);
      let checkedCount = value.length;
      // console.log(checkedCount);
      this.addForm.auth[index].checkAll =
        checkedCount === this.addForm.auth[index].options.length;
      this.addForm.auth[index].isIndeterminate =
        checkedCount > 0 &&
        checkedCount < this.addForm.auth[index].options.length;
    },
    // update
    handleCheckAllChange1(val, index) {
      if (this.updateForm.auth[index].checkAll) {
        for (const item of this.updateForm.auth[index].options) {
          this.updateForm.auth[index].checkedOptions.push(item.alias);
        }
      } else {
        this.updateForm.auth[index].checkedOptions = []; //取消全选，清空checkbox-group的数组
      }

      console.log(this.updateForm.auth[index].checkedOptions);
      this.updateForm.auth[index].isIndeterminate = false;
    },
    handleCheckedCitiesChange1(value, index) {
      // console.log(value, index);
      let checkedCount = value.length;
      // console.log(checkedCount);
      this.updateForm.auth[index].checkAll =
        checkedCount === this.updateForm.auth[index].options.length;
      this.updateForm.auth[index].isIndeterminate =
        checkedCount > 0 &&
        checkedCount < this.updateForm.auth[index].options.length;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addForm":
              console.log(this.addForm, "提交新表单");
              this.postRole()
              break;
            case "updateForm":
              console.log(this.updateForm, "提交修改表单");
              break;

            default:
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scope>
</style>

