<template>
  <div class="home">
    <!-- 头部 -->
    <div class="header">
      <div class="logo">管理后台</div>
      <div class="setting">
        <el-dropdown @command="handleCommand" trigger="click">
          <span class="el-dropdown-link">
            {{ userInfo.user_name }}&nbsp;
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="self">个人信息</el-dropdown-item>
            <!-- <el-dropdown-item command="refreshToken">刷新令牌</el-dropdown-item>
            <el-dropdown-item command="updatePassword">更新密码</el-dropdown-item>
            <el-dropdown-item command="queryUserMenuTree">获取菜单</el-dropdown-item> -->
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="container">
      <!-- 侧边栏 -->
      <div :class="!isCollapse ? 'aside-out' : 'aside-out1'">
        <!-- <div v-if="!isCollapse" class="isCollapse-btn" @click="turnIsCollapse">
          &lt;&lt;&lt;
        </div>
        <div v-if="isCollapse" class="isCollapse-btn" @click="turnIsCollapse">
          &gt;&gt;&gt;
        </div>-->
        <div :class="!isCollapse ? 'aside-inner' : 'aside-inner1'">
          <el-menu
            class="el-menu-vertical"
            :default-active="onRoutes"
            background-color="#324157"
            text-color="#bfcbd9"
            active-text-color="#20a0ff"
            unique-opened
            router
            :collapse="isCollapse"
            collapse-transition
          >
            <template v-for="item in sidebar">
              <template v-if="item.subs">
                <el-submenu :index="item.index" :key="item.index">
                  <template slot="title">
                    <i :class="item.icon"></i>
                    <span slot="title">{{ item.title }}</span>
                  </template>
                  <template v-for="subItem in item.subs">
                    <el-submenu
                      v-if="subItem.subs"
                      :index="subItem.index"
                      :key="subItem.index"
                    >
                      <template slot="title">{{ subItem.title }}</template>
                      <el-menu-item
                        v-for="(threeItem, i) in subItem.subs"
                        :key="i"
                        :index="threeItem.index"
                        >{{ threeItem.title }}</el-menu-item
                      >
                    </el-submenu>
                    <el-menu-item
                      v-else
                      :index="subItem.index"
                      :key="subItem.index"
                      >{{ subItem.title }}</el-menu-item
                    >
                  </template>
                </el-submenu>
              </template>
              <template v-else>
                <el-menu-item :index="item.index" :key="item.index">
                  <i :class="item.icon"></i>
                  <span slot="title">{{ item.title }}</span>
                </el-menu-item>
              </template>
            </template>
          </el-menu>
        </div>
      </div>
      <!-- 主要内容 -->
      <div class="main">
        <router-view></router-view>
      </div>
    </div>
    <!--  -->
    <el-dialog
      title="修改个人信息"
      :visible.sync="infoDialogFormVisible"
      :before-close="handleClose"
      @closed="closeDialog('infoForm')"
    >
      <el-form
        :model="infoForm"
        :rules="infoFormRules"
        ref="infoForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="修改昵称：" prop="nickname">
          <el-input v-model="infoForm.nickname"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="infoDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('infoForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sidebar } from "./menu.js";
import { _getSTS, _getState, _getProduct } from "../api/index.js";

import Roles from "@/views/Auth/Roles.vue";
import router from "../router/index.js";

import { _getAdminSelf, _putAdminSelf } from "../api/index.js";
export default {
  name: "Home",
  data() {
    return {
      sidebar,
      isCollapse: false, //false不折叠 true折叠
      aliSTS: [],
      userInfo: {
        user_name: "个人信息",
      }, //用户信息
      //
      infoDialogFormVisible: false,
      infoForm: {
        ID: "",
        nickname: "",
      },
      infoFormRules: {
        nickname: [
          { required: true, message: "请输入账户昵称", trigger: "blur" },
        ],
      },
      state: "",
      // --------------------生成app产品选项--------------------
      productList: [],
      productListOptions: [{ value: "0", label: "全部" }],
    };
  },
  mounted() {
    this.getSTS();
    this.getState();
    this.getProductList();
    this.getTokenInfo()
  },
  methods: {
    // --------------------请求函数--------------------
    async STS() {
      let result = await _getSTS({});
      if (result && result.status === 200) {
        this.aliSTS = result.data;
        console.log(this.aliSTS);
        sessionStorage.setItem("AccessKeyId", this.aliSTS.accessKeyId);
        sessionStorage.setItem("AccessKeySecret", this.aliSTS.accessKeySecret);
        sessionStorage.setItem("Expiration", this.aliSTS.expiration);
        sessionStorage.setItem("SecurityToken", this.aliSTS.securityToken);
      }
    },
    getSTS() {
      let id = sessionStorage.getItem("AccessKeyId");
      let secret = sessionStorage.getItem("AccessKeySecret");
      let time = sessionStorage.getItem("Expiration");
      let token = sessionStorage.getItem("SecurityToken");
      // 判断session中是否有，没有就请求，有就判断有没有过期是不是要重新请求
      if (id == null && secret == null && time == null && token == null) {
        console.log("没有token第一次请求");
        this.STS();
      } else {
        time = new Date(time).getTime();
        let nowTime = Date.now();
        if (time <= nowTime) {
          console.log("token过期重新请求");
          this.STS();
        } else {
          console.log("现有token没过期");
        }
      }
    },
    async getAdminSelf() {
      let res = await _getAdminSelf({
        withRoles: true,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        // console.log(res.data, '/admin/self');
        this.infoForm.ID = res.data.ID;
        this.infoForm.nickname = res.data.nickname;
      }
    },
    async putAdminSelf() {
      let res = await _putAdminSelf({
        nickname: this.infoForm.nickname,
      });
      if (res && (res.status === 200 || res.status === 204)) {
        console.log(res);
        this.$message.success("修改成功");
        this.infoDialogFormVisible = false;
      }
    },
    // 请求页面url的state参数
    async getState() {
      let res = await _getState({});
      if (res && res.status === 200) {
        // console.log(res.data, "url的state参数");
        this.state = res.data;
      }
    },
    // 请求产品列表
    async getProductList() {
      let result = await _getProduct({});
      if (result && result.status === 200) {
        this.productList = result.data.list;
        // console.log(this.productList);
        for (const key in this.productList) {
          let obj = {
            value: this.productList[key].ID,
            label: this.productList[key].name,
          };
          this.productListOptions.push(obj);
        }
        // console.log(this.productListOptions,'options');
      }
    },
    // --------------------表单提交--------------------
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "infoForm":
              console.log(this.infoForm);
              this.putAdminSelf();
            default:
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // --------------------函数处理--------------------

    turnIsCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    handleCommand(command) {
      switch (command) {
        case "self":
          this.getAdminSelf();

          this.infoDialogFormVisible = true;
          break;
        case "refreshToken":
          this.refreshToken();
          break;

        case "updatePassword":
          this.updatePassword();
          break;

        case "queryUserMenuTree":
          this.queryUserMenuTree();
          break;

        case "logout":
          console.log("logout");
          let token = localStorage.getItem("idToken");
          let state = this.state;

          // let url = `http://192.168.2.134:4444/oauth2/sessions/logout?id_token_hint=${token}&post_logout_redirect_uri=http://localhost:3001/login&state=${state}`;
          // let url = `http://hydrapubic.talku.vip/oauth2/sessions/logout?id_token_hint=${token}&post_logout_redirect_uri=http://192.168.2.111:3001/login&state=${state}`;
          // let url = `https://hydrapubic.talku.vip/oauth2/sessions/logout?id_token_hint=${token}&post_logout_redirect_uri=https://union.talku.vip/login&state=${state}`
          let url = `https://hydrapubic.distributorshub.com/oauth2/sessions/logout?id_token_hint=${token}&post_logout_redirect_uri=https://union.distributorshub.com/login&state=${state}`

          console.log(url);
          localStorage.clear();
          window.location.href = url;

          break;

        default:
          break;
      }
    },
    handleClose(done) {
      this.$messagebox
        .confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    closeDialog(formName) {
      this.$refs[formName].resetFields();
    },
    getTokenInfo() {
      let idToken = localStorage.getItem("idToken");
      // console.log(idToken,'拿本地存的token');
      // token中是.分隔的有三段信息
      let arr = idToken.split(".");
      // console.log(arr);
      // 用户自己的信息在第二段 固定在arr[1]
      let a = JSON.parse(new Buffer(arr[1], "base64").toString());
      // console.log(a);
      let b = parseInt(JSON.parse(a.sub).uuid);
      this.uuid = b;
      console.log(this.uuid, "该用户的uuid");
      sessionStorage.setItem("uuid", this.uuid);
    },
  },

  computed: {
    onRoutes() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped>
.home {
  /* min-width: 1100px; */
  width: 100%;
  height: 100%;
}

.header {
  height: 70px;
  width: 100%;
  background-color: #242f42;
  color: #fff;
  overflow: hidden;
}

.setting {
  box-sizing: border-box;
  margin: 15px 15px 0 0;
  float: right;
  width: 100px;
  height: 40px;
  text-align: center;
  color: #f0f0f0;
  line-height: 40px;
}

/* .setting i {
  font-size: 20px;
} */
.el-dropdown-link {
  color: #fff;
}

.logo {
  float: left;
  width: 250px;
  line-height: 70px;
  margin-left: 20px;
  font-size: 22px;
}

.container {
  height: 100%;
  display: flex;
}

/* 侧边栏隐藏滚动条设置 */
.aside-out {
  float: left;
  width: 200px;
  min-width: 200px;
  height: calc(100% - 70px);
  overflow: hidden;
}

.aside-out1 {
  float: left;
  width: 64px;
  min-width: 64px;
  height: calc(100% - 70px);
  overflow: hidden;
}

.isCollapse-btn {
  background: #324157;
  color: rgb(191, 203, 217);
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}

.aside-inner {
  width: 200px;
  height: 100%;
  background-color: #324157;
  overflow-x: hidden;
  overflow-y: scroll;
}

.aside-inner1 {
  width: 64px;
  height: 100%;
  background-color: #324157;
  overflow-x: hidden;
  overflow-y: scroll;
}

.el-menu-vertical:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu {
  border-right: none;
}

/* .el-submenu {
  width: 100%!important;
  width: 217px;
  width: 217px !important;
} */
.main {
  flex: 1;
  float: right;
  width: auto;
  height: calc(100% - 71px);
  /* height: calc(100% - 52px); */
  background-color: #f0f0f0;
  /* padding: 10px; */
  overflow-y: scroll;
}

/*  */
.user-name {
  margin-left: 10px;
  color: #fff;
}

.user-avator {
  margin-left: 20px;
  border-radius: 50%;
  overflow: hidden;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

::-webkit-scrollbar {
  width: 0 !important;
}
</style>
